import React, { useState } from "react";
import {
    Box,
    Menu,
    MenuList,
    MenuItem,
    IconButton,
    ListItemIcon,
    Typography,
} from '@mui/material/';

import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';

import purple from '@mui/material/colors/purple';
import cyan from '@mui/material/colors/cyan';
import lime from '@mui/material/colors/lime';
import pink from '@mui/material/colors/pink';

function AuthItemMenu(props) {
    const {
        display,
        update_main_display_action
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const openAccountHandleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const closeAccountHandleClick = (event) => {
        setAnchorEl(null);
    };

    return (
        <div >
            <IconButton
                color={'primary'}
                onClick={openAccountHandleClick}
                size='small'
            >
                <AccountCircle />
            </IconButton>

            <Menu
                id="long-menu"
                disableScrollLock={true}
                anchorEl={anchorEl}
                keepMounted
                getcontentanchorel={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{ style: { width: 215 } }}
                open={open}
                onClose={closeAccountHandleClick}
            >
                <MenuList>
                    <MenuItem
                        disabled={display === 'home_page_landing'}
                        onClick={() => {
                            update_main_display_action('home_page_landing');
                            setAnchorEl(null);
                        }}
                        sx={{ marginBottom: '2px' }}
                    >
                        <ListItemIcon sx={{ '& svg': { color: pink[500] } }}>
                            <HomeIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Home Page</Typography>
                    </MenuItem>

                    <MenuItem
                        disabled={display === 'member_sign_in'}
                        onClick={() => {
                            update_main_display_action('member_sign_in');
                            setAnchorEl(null);
                        }}
                        sx={{ marginBottom: '2px' }}
                    >
                        <ListItemIcon sx={{ '& svg': { color: cyan[500] } }}>
                            <EnhancedEncryptionIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Sign In</Typography>
                    </MenuItem>

                    <MenuItem
                        disabled={display === 'member_sign_up'}
                        onClick={() => {
                            update_main_display_action('member_sign_up');
                            setAnchorEl(null);
                        }}
                        sx={{ marginBottom: '2px' }}
                    >
                        <ListItemIcon sx={{ '& svg': { color: lime[500] } }}>
                            <AccountBoxIcon />
                        </ListItemIcon>
                        <Box sx={{ fontSize: '16px' }}>Sign Up</Box>
                    </MenuItem>

                    <MenuItem
                    disabled={display === 'member_change_password'}
                        onClick={() => {
                            update_main_display_action('member_change_password');
                            setAnchorEl(null);
                        }}
                    >
                        <ListItemIcon sx={{ '& svg': { color: purple[500] } }}>
                            <ManageAccountsIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Change Password</Typography>
                    </MenuItem>
                </MenuList>
            </Menu>
        </div>
    );
}

export default AuthItemMenu;