import * as React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
} from '@mui/material/';

import { visuallyHidden } from '@mui/utils';

import { useSelector, useDispatch } from 'react-redux';
import {
    fetchDeviceListAction,
    sortDeviceColumnFieldAction,
    setPageAction,
    setRowsPerPageAction
} from "../../redux/deviceSlice"

import DeviceTableToolbar from './DeviceTableToolbar';
import DeviceSearch from "./DeviceSearch";

const columnTitle = [
    {
        id: 'id',
        label: 'Id',
    },
    {
        id: 'device_serial',
        label: 'Serial',
    },
    {
        id: 'device_name',
        label: 'Device Name',
    },
    /* {
        id: 'owner_mobile',
        label: 'Owner Mobile',
    },
    {
        id: 'owner_location',
        label: 'Owner Location',
    }, */
];

export default function DeviceListTable() {
    const dispatch = useDispatch();

    const deviceList = useSelector((state) => state.device.deviceList);
    const rowsTotalCount = useSelector((state) => state.device.rowsTotalCount);
    const page = useSelector((state) => state.device.page);
    const rowsPerPage = useSelector((state) => state.device.rowsPerPage);
    const order = useSelector((state) => state.device.order);
    const orderBy = useSelector((state) => state.device.orderBy);

    const [selected, setSelected] = React.useState(null);

    React.useEffect(() => {
        dispatch(fetchDeviceListAction());
    }, [dispatch, page, rowsPerPage]);

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(sortDeviceColumnFieldAction({ orderBy: property, order: isAsc ? 'desc' : 'asc' }));
    };

    const handleChangePage = (event, newPage) => {
        console.log('newPage: ' + newPage);
        dispatch(setPageAction(newPage));
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(setRowsPerPageAction(parseInt(event.target.value, 10)));
        dispatch(setPageAction(0));
    };

    const handleClick = (row) => {
        setSelected(row);
        //console.log('DeviceListTable - deviceList: '+JSON.stringify(deviceList[index]));

    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box >
                <h1>Karaoke Device List</h1>
            </Box>
            <Box sx={{
                float: 'right',
            }}>
                <DeviceTableToolbar
                    //dataList={deviceList}
                    selected={selected}
                    setSelected={setSelected}
                />
            </Box>
            <div style={{ margin: '10px 0px' }}>
                <DeviceSearch />
            </div>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <colgroup >
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '30%' }} />
                            <col style={{ width: '60%' }} />
                        </colgroup>
                        <TableHead>
                            <TableRow >
                                {columnTitle.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.center ? 'center' : 'left'}
                                        padding="normal"
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deviceList.map(row => (
                                <TableRow
                                    key={row.id}
                                    selected={selected !== null ? row.id === selected.id : null}
                                    onClick={() => handleClick(row)}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        '& .MuiTableCell-root': {
                                            //color: row.import_status === 'not qualified' ? 'red' : null
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="left">{row.device_serial}</TableCell>
                                    <TableCell align="left">{row.device_name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    component="div"
                    count={rowsTotalCount}
                    labelRowsPerPage={"per page"}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /* sx={{
                    background: 'red',
                    '& .MuiSelect-select': {
                        display: 'none !important'
                    },
                    '& > div.MuiToolbar-root > div.MuiInputBase-root > svg': {
                        display: 'none !important'
                    }
                }} */
                />
            </Paper>
        </Box>
    );
}
