import React, { useState } from 'react';
import {
    Box,
    TextField,
    InputAdornment,
    Button,
    Paper,
    Link,
    Grid,
    Container,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material/';

import PhoneAndroid from '@mui/icons-material/PhoneAndroid';
import Lock from '@mui/icons-material/Lock';

function MemberSignIn(props) {
    const {
        memberAuthMessage,
        memberAuthOpenAlert,
        closeMemberAuthAlertAction,
        loginCurrentMemberAction,
        update_main_display_action
    } = props;

    const [memberMobile, setMemberMobile] = useState('');
    const [memberPassword, setMemberPassword] = useState('');

    const memberMobileChanged = (event) => {
        setMemberMobile(event.target.value);
    }

    const memberPasswordChanged = (event) => {
        setMemberPassword(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        loginCurrentMemberAction({
            memberMobile: event.target['memberMobile'].value,
            memberPassword: event.target['memberPassword'].value
        });
    };

    const handleClose = () => {
        setMemberMobile('')
        setMemberPassword('');
        closeMemberAuthAlertAction();
    };

    return (
        <div>
            <Dialog
                open={memberAuthOpenAlert}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Thông báo"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {memberAuthMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Container maxWidth="lg">
                <Grid container sx={{
                    pt: { xs: '50px', md: '160px' },
                }}>
                    <Grid item xs={0} md={6} lg={5} align="center">

                    </Grid>
                    <Grid item xs={0} md={0} lg={2} align="center">

                    </Grid>
                    <Grid item xs={12} md={5} lg={5} align="center">
                        <Paper
                            sx={{
                                m: '0px -4px',
                                p: { xs: '10px 20px', sm: '20px 20px', md: '30px 30px' },
                                maxWidth: 500,
                                background: '#eeeeee'
                            }}
                        >
                            <Box sx={{
                                fontSize: { xs: '22px', md: '24px' },
                            }}>
                                Đăng nhập tài khoản
                            </Box>
                            <Box sx={{
                                fontSize: { xs: '12px', sm: '13px', md: '18px' },
                                paddingTop: { xs: '8px', md: '18px' },
                            }}>
                                Điền vào điện thoại di động và mật khẩu của bạn để đăng nhập.
                            </Box>
                            <form onSubmit={handleSubmit}>
                                <Box sx={{
                                    paddingTop: { xs: '12px', md: '18px' },
                                }}>
                                    <div spellCheck="false">
                                        <TextField
                                            name="memberMobile"
                                            placeholder="Số điện thoại di động"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            //type="number"
                                            required={true}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneAndroid />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={memberMobile}
                                            onChange={memberMobileChanged}
                                            sx={{
                                                //mt: 1,
                                                '& svg': {
                                                    color: (theme) => theme.palette.grey[400],
                                                    fontSize: 22,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div spellCheck="false">
                                        <TextField
                                            name="memberPassword"
                                            placeholder="Mật khẩu"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            type="password"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={memberPassword}
                                            onChange={memberPasswordChanged}
                                            sx={{
                                                pt: 1,
                                                '& svg': {
                                                    color: (theme) => theme.palette.grey[400],
                                                    fontSize: 22,
                                                },
                                            }}
                                        />
                                    </div>
                                </Box>
                                <div >
                                    <Link href="#" color='primary'>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                mt: 0.5,
                                            }}
                                        >
                                            Forgot Password?
                                        </Typography>
                                    </Link>
                                </div>
                                <Box>
                                    <Button
                                        variant="contained"
                                        type="submit" fullWidth={true}
                                        sx={{ pardingTop: '2px', mb: { xs: 0, md: 2 } }}
                                    >
                                        Đăng Nhập
                                    </Button>
                                </Box>
                            </form>

                            <Box align='center' sx={{ fontSize: '12px', mt: '20px' }}>
                                Vẫn chưa có tài khoản?{" "}
                                <Link
                                    href="#"
                                    underline="hover"
                                    sx={{ fontSize: '16px' }}
                                    onClick={() => { update_main_display_action('member_sign_up') }}
                                >
                                    Đăng ký tài khoản mới.
                                </Link>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default MemberSignIn;