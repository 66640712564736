import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authHeader from "../components/token/auth-header";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT + "/api/";

const getApiParams = (page, rowsPerPage, searchColumn, searchContent, orderBy, order) => {
    let params = {};
    params["page"] = page;
    params["rowsPerPage"] = rowsPerPage;
    params["searchColumn"] = searchColumn;
    params["searchContent"] = searchContent;
    params["orderBy"] = orderBy;
    params["order"] = order;
    return params;
}

const initialState = {
    isLoading: false,
    hasError: false,
    songList: [],
    rowsTotalCount: 0,
    page: 0,
    rowsPerPage: 10,
    searchColumn: 'song_id',
    searchContent: '',
    orderBy: 'song_id',
    order: 'asc'
}

export const fetchSongListAction = createAsyncThunk(
    'song/fetchSongListAction',
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.song.page;
        const searchColumn = store.song.searchColumn;
        const searchContent = store.song.searchContent;
        const rowsPerPage = store.song.rowsPerPage;
        const orderBy = store.song.orderBy;
        const order = store.song.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        console.log(JSON.stringify(params))

        const response = await axios.get(BASE_URL + "fetch_song_list", { params, headers: authHeader() })
        return response.data
    }
)

export const sortColumnFieldAction = createAsyncThunk(
    'song/sortColumnFieldAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.song.page;
        const rowsPerPage = store.song.rowsPerPage;
        const searchColumn = store.song.searchColumn;
        const searchContent = store.song.searchContent;
        
        const { orderBy, order } = data;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);

        const response = await axios.get(BASE_URL + "fetch_song_list", { params, headers: authHeader() })
        return response.data
    }
)

export const songSlice = createSlice({
    name: 'song',
    initialState,
    reducers: {
        setPageAction: (state, action) => {
            state.page = action.payload
        },
        setRowsPerPageAction: (state, action) => {
            state.rowsPerPage = action.payload
        },
        setSearchColumnAction: (state, action) => {
            state.searchColumn = action.payload
        },
        setSearchContentAction: (state, action) => {
            state.searchContent = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSongListAction.fulfilled, (state, action) => {
                //console.log(JSON.stringify(action.payload))

                state.songList = action.payload.dataList
                state.rowsTotalCount = action.payload.rowCount
                //state.page = action.payload.page
                //state.rowsPerPage = action.payload.rowsPerPage
                state.searchContent = action.payload.searchContent
                state.orderBy = action.payload.orderBy
                state.order = action.payload.order
            })
            .addCase(sortColumnFieldAction.fulfilled, (state, action) => {
                //console.log(JSON.stringify(action.payload))
                
                state.songList = action.payload.dataList
                state.rowsTotalCount = action.payload.rowCount
                //state.page = action.payload.page
                //state.rowsPerPage = action.payload.rowsPerPage
                state.searchContent = action.payload.searchContent
                state.orderBy = action.payload.orderBy
                state.order = action.payload.order
            })
    },
})

export const {
    setPageAction,
    setRowsPerPageAction,
    setSearchColumnAction,
    setSearchContentAction
} = songSlice.actions

export default songSlice.reducer;