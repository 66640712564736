export default function authHeader() {
    const account = JSON.parse(localStorage.getItem('member'));

    if (account.accessToken) {
        //console.log('token found');
        return {
            'x-access-token': account.accessToken
        };
    } else {
        //console.log('no token');
        return {};
    }
}
