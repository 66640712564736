
import React from "react";
import { useDispatch } from 'react-redux';
import {
    update_main_display_action
} from "../redux/mainSlice";

import AppItemMenu from './AppItemMenu';
import AuthItemMenu from './AuthItemMenu';

function HeaderActivity(props) {
    const {
        display,
    } = props;

    const dispatch = useDispatch();

    return (
        <div>
            {/* {console.log('---> HeaderActivity - display: ' + display)}
            {console.log('---> HeaderActivity - localStorage - client: ' + localStorage.getItem('client'))} */}

            {localStorage.getItem('member') !== null ?
                <AppItemMenu
                    display={display}
                    update_main_display_action={(payload) => dispatch(update_main_display_action(payload))}
                />
                :
                <AuthItemMenu
                    display={display}
                    update_main_display_action={(payload) => dispatch(update_main_display_action(payload))}
                />}
        </div>
    );
}

export default HeaderActivity;