import React from "react";
import {
  Box,
  Container
} from '@mui/material/';

import { useSelector, useDispatch } from 'react-redux';

import {
  update_main_display_action
} from "./redux/mainSlice";

import {
  registerNewMemberAction,
  loginCurrentMemberAction,
  changeMemberPasswordAction,
  closeMemberAuthAlertAction
} from "./redux/memberSlice";

import HeaderActivity from './header/HeaderActivity';

import HomePage from './landing/HomePage';
import MemberSignUp from './member/MemberSignUp';
import MemberSignIn from './member/MemberSignIn';
import MemberChangePassword from './member/MemberChangePassword';

import SongListTable from './manager/live/SongListTable';
import UploadManager from './manager/production/UploadManager';
import DownloadLanding from './manager/download/DownloadLanding';
import DeviceListTable from './manager/device/DeviceListTable';

export default function AppNavigation(props) {
  const {
    display,
  } = props;

  const dispatch = useDispatch();

  //const accountAuthMessage = useSelector((state) => state.account.accountAuthMessage);
  //const accountAuthOpenAlert = useSelector((state) => state.account.accountAuthOpenAlert);

  const memberAuthMessage = useSelector((state) => state.member.memberAuthMessage);
  const memberAuthOpenAlert = useSelector((state) => state.member.memberAuthOpenAlert);


  function FunctionRouting(state) {
    //console.log('AppNavigation ----> state: ' + state);

    switch (state) {

      case 'member_sign_up':
        return <MemberSignUp
          memberAuthMessage={memberAuthMessage}
          memberAuthOpenAlert={memberAuthOpenAlert}
          closeMemberAuthAlertAction={() => dispatch(closeMemberAuthAlertAction())}
          registerNewMemberAction={(payload) => dispatch(registerNewMemberAction(payload))}
          update_main_display_action={(payload) => dispatch(update_main_display_action(payload))}
        />

      case 'member_sign_in':
        return <MemberSignIn
          memberAuthMessage={memberAuthMessage}
          memberAuthOpenAlert={memberAuthOpenAlert}
          closeMemberAuthAlertAction={() => dispatch(closeMemberAuthAlertAction())}
          loginCurrentMemberAction={(payload) => dispatch(loginCurrentMemberAction(payload))}
          update_main_display_action={(payload) => dispatch(update_main_display_action(payload))}
        />

      case 'member_change_password':
        return <MemberChangePassword
          closeMemberAuthAlertAction={() => dispatch(closeMemberAuthAlertAction())}
          changeMemberPasswordAction={(payload) => dispatch(changeMemberPasswordAction(payload))}
        />

      case 'display_song_list':
        return <SongListTable />

      case 'display_raw_list':
        return <UploadManager />

      case 'display_download_list':
        return <DownloadLanding />

      case 'display_device_list':
        return <DeviceListTable />

      default:
        return null
    }
  }

  return (
    <div>
      {/* {console.log('display: ' + display)} */}
      <Container maxWidth="xl">
        <Box sx={{
          float: 'right',
          marginTop: { md: '6px', xs: '0px' },
        }}>
          <HeaderActivity
            display={display}
          />
        </Box>
      </Container>
      <div>
        {FunctionRouting(display)}
      </div>
    </div>
  )
} 