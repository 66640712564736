import React, { useRef, useState } from "react";
import {
    Box,
    Avatar,
    Typography,
    IconButton
} from '@mui/material/';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArticleIcon from '@mui/icons-material/Article';

import { useSelector, useDispatch } from 'react-redux';
import {
    importCsvDataAction,
    setCsvDataListAction,
    resetCsvDataImportAction
} from "../../../redux/importSlice"

const ImportActivity = () => {
    const dispatch = useDispatch();
    const csvList = useSelector((state) => state.import.csvList);
    const importedCsvCount = useSelector((state) => state.import.importedCsvCount);
    const csvListLength = useSelector((state) => state.import.csvListLength);
    const importStatus = useSelector((state) => state.import.importStatus);

    const input_reader = useRef(null);
    const [fileName, setFileName] = useState('click icon to upload csv file');

    const inputFileChanged = (e) => {
        if (window.FileReader) {
            let file = e.target.files[0], reader = new FileReader();
            e.target.value = '';

            if (file !== undefined) {
                setFileName(file.name);
                reader.readAsText(file);
            }
            else {
                console.log("file is undefined");
            }

            let data = [];
            reader.onload = function (r) {
                let jsonData = reader.result.split('\n');

                jsonData.forEach((element, index) => {

                    if (index) {
                        const elementRaw = element.split('$');
                        if (element) {
                            var qualified = 'not qualified';
                            if (elementRaw[0].trim().length > 0 && elementRaw[1].length > 0 && elementRaw[2].length > 0) qualified = 'qualified';

                            let param = {
                                'id': index,
                                'song_id': elementRaw[0].trim(),
                                'song_title': elementRaw[1],
                                'singer_name_1': elementRaw[2],
                                'upload_source': elementRaw[3],
                                'import_status': qualified
                            }
                            data.push(param);
                        }
                    }
                })

                dispatch(setCsvDataListAction(data));
            }
        }
        else {
            alert('Sorry, your browser does\'nt support for preview');
        }
    };

    const handleClick = () => {
        input_reader.current.click();
    };

    const submitImportHandle = () => {
        dispatch(importCsvDataAction({ index: 0, list: csvList }));
    };

    const cancelImportHandle = () => {
        dispatch(resetCsvDataImportAction());
    };

    const completeImportHandle = () => {
        setFileName('click icon to upload csv file');
        dispatch(resetCsvDataImportAction());
    };

    function updateTextFieldsHandle(state) {
        //console.log('::: textFieldsHandle: '+state)
        switch (state) {
            case 'ready':
                return fileName
            case 'processing':
                return importedCsvCount + '/' + csvListLength
            case 'complete':
                return importedCsvCount + '/' + csvListLength
            default:
                return 'click icon to upload csv file'
        }
    }

    function updateStatusHandle(state) {
        //console.log('::: importStatusHandle: '+state)
        switch (state) {
            case 'ready':
                if (csvList.length > 0) {
                    //console.log('greater than 0');
                    return (
                        <Box sx={{
                            display: 'flex',
                            ml: 'auto'
                        }}>
                            <div>
                                <Avatar
                                    variant="square"
                                    onClick={cancelImportHandle}
                                    sx={{
                                        bgcolor: '#2196f3',
                                        width: '100px',
                                        height: '24px',
                                        cursor: 'pointer',
                                        marginRight: '10px'
                                    }}
                                >
                                    <Typography fontSize='14px' component="div">
                                        Cancel
                                    </Typography>
                                </Avatar>
                            </div>
                            <div>
                                <Avatar
                                    variant="square"
                                    onClick={submitImportHandle}
                                    sx={{
                                        bgcolor: '#2196f3',
                                        width: '100px',
                                        height: '24px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Typography fontSize='14px' component="div">
                                        Submit
                                    </Typography>
                                </Avatar>
                            </div>
                        </Box>
                    )

                } else {
                    //console.log('equal to 0')
                    return (
                        <Box sx={{
                            display: 'flex',
                            ml: 'auto'
                        }}>
                            <div>
                                <Avatar
                                    variant="square"
                                    onClick={cancelImportHandle}
                                    sx={{
                                        bgcolor: '#2196f3',
                                        width: '100px',
                                        height: '24px',
                                        cursor: 'pointer',
                                        marginRight: '10px'
                                    }}
                                >
                                    <Typography fontSize='14px' component="div">
                                        Cancel
                                    </Typography>
                                </Avatar>
                            </div>
                        </Box>
                    )
                }
            case 'complete':
                return (
                    <Box sx={{ ml: 'auto' }}>
                        <Avatar
                            variant="square"
                            onClick={completeImportHandle}
                            sx={{
                                bgcolor: '#2196f3',
                                width: '100px',
                                height: '24px',
                                cursor: 'pointer'
                            }}
                        >
                            <Typography fontSize='14px' component="div">
                                Continue
                            </Typography>
                        </Avatar>
                    </Box>
                )
            default:
                return null
        }
    }

    return (
        <div style={{
            display: 'flex',
            verticalAlign: 'middle',
            alignItems: 'center',
            marginBottom: '10px'
        }}>
            <div>
                <div style={{
                    display: 'flex',
                    verticalAlign: 'middle',
                    alignItems: 'center'
                }}>
                    <div style={{ marginRight: '20px' }}>
                        {importStatus === '' ? (
                            <IconButton
                                onClick={handleClick}
                            >
                                <CloudUploadIcon
                                    color='primary'
                                    style={{ cursor: 'pointer' }}
                                />
                            </IconButton>
                        ) : (
                            <ArticleIcon color='primary' />
                        )}

                    </div>
                    <div>
                        {updateTextFieldsHandle(importStatus)}
                    </div>
                </div>
            </div>

            {updateStatusHandle(importStatus)}

            <input type="file" ref={input_reader} style={{ display: 'none' }} onChange={inputFileChanged} accept=".csv" />
        </div>
    )
}

export default ImportActivity;