import * as React from 'react';
import {
    Container,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    Checkbox,
    FormControlLabel,
    Switch
} from '@mui/material/';

import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';

import { useSelector, useDispatch } from 'react-redux';
import {
    fetchDownloadListAction,
    sortVideoColumnFieldAction,
    setPageAction,
    setRowsPerPageAction
} from "../../redux/downloadSlice"

import DownloadTableToolbar from './DownloadTableToolbar';
import DownloadVideoSearch from "./DownloadVideoSearch";
import DownloadController from "./DownloadController";
//import UploadVideo from "./UploadVideo";

const headCells = [
    {
        id: 'downloadSongId',
        numeric: false,
        disablePadding: true,
        label: 'Song Id',
    },
    {
        id: 'downloadSongTitle',
        numeric: false,
        disablePadding: false,
        label: 'Song Title',
    },
    {
        id: 'downloadSource',
        numeric: false,
        disablePadding: false,
        label: 'Source',
    },
    {
        id: 'downloadThumbnailUrl',
        numeric: false,
        disablePadding: false,
        label: 'Download Url',
    }
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        uploadType
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    {uploadType === 'multi' ? <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    /> : null}
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function DownloadLanding() {
    const dispatch = useDispatch();

    const isDownloading = useSelector((state) => state.download.isDownloading);
    const downloadList = useSelector((state) => state.download.downloadList);
    const rowsTotalCount = useSelector((state) => state.download.rowsTotalCount);
    const page = useSelector((state) => state.download.page);
    const rowsPerPage = useSelector((state) => state.download.rowsPerPage);
    const order = useSelector((state) => state.download.order);
    const orderBy = useSelector((state) => state.download.orderBy);

    const [selected, setSelected] = React.useState([]);

    const [dense, setDense] = React.useState(false);
    const [uploadType, setUploadType] = React.useState('single');

    React.useEffect(() => {
        dispatch(fetchDownloadListAction());
    }, [page, rowsPerPage, dispatch]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(sortVideoColumnFieldAction({ orderBy: property, order: isAsc ? 'desc' : 'asc' }));
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = downloadList.map((n) => n);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const multiSelectHandle = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    const singleSelectHandle = (event, id) => {
        //console.log('singleSelectHandle: '+id);
        setSelected([id])
    }

    const handleChangePage = (event, newPage) => {
        dispatch(setPageAction(newPage));
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(setRowsPerPageAction(parseInt(event.target.value, 10)));
        dispatch(setPageAction(0));
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (downloadSongId) => selected.indexOf(downloadSongId) !== -1;

    return (
        <Container maxWidth="xl">
            <Box >
                <h1>Display Song Download List {rowsPerPage}</h1>
            </Box>
            <DownloadTableToolbar
                dataList={downloadList}
                selected={selected}
                setSelected={setSelected}
                uploadType={uploadType}
                setUploadType={setUploadType}
            />
            <div style={{ margin: '10px 0px' }}>
                <DownloadVideoSearch />
            </div>
            <DownloadController
                selected={selected}
                isDownloading={isDownloading}
            />
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <colgroup >
                            <col style={{ width: '5%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '40%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '30%' }} />
                        </colgroup>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={downloadList !== undefined ? downloadList.length : 0}
                            uploadType={uploadType}
                        />
                        <TableBody>
                            {downloadList.map((row, index) => {
                                const isItemSelected = isSelected(row);

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => uploadType === 'single' ? singleSelectHandle(event, row) : multiSelectHandle(event, row)}
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.downloadSongId}
                                        </TableCell>
                                        <TableCell >{row.downloadSongTitle}</TableCell>
                                        <TableCell >{row.downloadSource}</TableCell>
                                        <TableCell >{row.downloadSongUrl}</TableCell>
                                        {/* <TableCell align="center">
                                            <UploadVideo
                                                button_on={isItemSelected && uploadType === 'single' ? true : false}
                                                row_id={isItemSelected ? row.downloadSongId : null}
                                            />
                                        </TableCell> */}
                                    </TableRow>
                                );
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={rowsTotalCount}
                    labelRowsPerPage={""}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Container>
    );
}
