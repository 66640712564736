import * as React from 'react';
import {
    Container,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    Checkbox,
    FormControlLabel,
    Switch
} from '@mui/material/';

import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';

import { useSelector, useDispatch } from 'react-redux';
import {
    fetchVideoListAction,
    sortVideoColumnFieldAction,
    setPageAction,
    setRowsPerPageAction
} from "../../../redux/uploadSlice"

import VideoTableToolbar from './VideoTableToolbar';
import UploadVideo from "./UploadVideo";
import VideoSearch from "./VideoSearch";

const headCells = [
    {
        id: 'song_id',
        numeric: false,
        disablePadding: true,
        label: 'Song Id',
    },
    {
        id: 'song_title',
        numeric: false,
        disablePadding: false,
        label: 'Song Title',
    },
    {
        id: 'singer_name_1',
        numeric: false,
        disablePadding: false,
        label: 'Singer Name 1',
    },
    {
        id: 'upload_source',
        numeric: false,
        disablePadding: false,
        label: 'Upload Source',
    },
    {
        id: 'import_status',
        numeric: false,
        disablePadding: false,
        label: 'Import Status',
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        uploadType
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    {uploadType === 'multi' ? <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    /> : null}
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
    const dispatch = useDispatch();

    const videoList = useSelector((state) => state.upload.videoList);
    const rowsTotalCount = useSelector((state) => state.upload.rowsTotalCount);
    const page = useSelector((state) => state.upload.page);
    const rowsPerPage = useSelector((state) => state.upload.rowsPerPage);
    const order = useSelector((state) => state.upload.order);
    const orderBy = useSelector((state) => state.upload.orderBy);

    const [selected, setSelected] = React.useState([]);

    const [dense, setDense] = React.useState(false);
    const [uploadType, setUploadType] = React.useState('single');

    React.useEffect(() => {
        dispatch(fetchVideoListAction());
    }, [dispatch, page, rowsPerPage]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(sortVideoColumnFieldAction({ orderBy: property, order: isAsc ? 'desc' : 'asc' }));
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = videoList.map((n) => n.song_id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const multiSelectHandle = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    const singleSelectHandle = (event, id) => {
        setSelected([id])
    }

    const handleChangePage = (event, newPage) => {
        dispatch(setPageAction(newPage));
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(setRowsPerPageAction(parseInt(event.target.value, 10)));
        dispatch(setPageAction(0));
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (song_id) => selected.indexOf(song_id) !== -1;

    return (
        <Container maxWidth="xl">
            <VideoTableToolbar
                dataList={videoList}
                selected={selected}
                setSelected={setSelected}
                uploadType={uploadType}
                setUploadType={setUploadType}
            />
            <div style={{ margin: '10px 0px'}}>
                <VideoSearch />
            </div>

            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={videoList !== undefined ? videoList.length : 0}
                            uploadType={uploadType}
                        />
                        <TableBody>
                            {videoList.map((row, index) => {
                                const isItemSelected = isSelected(row.song_id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => uploadType === 'single' ? singleSelectHandle(event, row.song_id) : multiSelectHandle(event, row.song_id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.song_id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.song_id}
                                        </TableCell>
                                        <TableCell >{row.song_title}</TableCell>
                                        <TableCell >{row.singer_name_1}</TableCell>
                                        <TableCell >{row.upload_source}</TableCell>
                                        <TableCell align="center">
                                            <UploadVideo
                                                button_on={isItemSelected && uploadType === 'single' ? true : false}
                                                row_id={isItemSelected ? row.song_id : null}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    component="div"
                    count={rowsTotalCount}
                    labelRowsPerPage={""}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Container>
    );
}
