import React, { useEffect } from "react";
import WebFont from 'webfontloader';
import { useSelector } from 'react-redux';

import HomePage from './landing/HomePage';
import AppNavigation from './AppNavigation';

function App() {
  const display = useSelector((state) => state.main.display);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Chilanka']
      }
    });
  }, []);

  function validateYourDevice() {
    if (display === 'home_page_landing') {
      return <div>
        <HomePage />
      </div>
    } else {
      return <div>
        <AppNavigation
          display={display}
        />
      </div>
    }
  }

  return (
    <div className="App" style={{
      fontFamily: 'Droid Sans'
    }}>
      {validateYourDevice()}
    </div>
  );
}

export default App;
