import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from '@mui/material/';

const isNumbers = (str) => /^[0-9-]*$/.test(str);

function CsvFormDialog(props) {
    const {
        openDialog,
        closeDialog,
        title,
        selectedRow,
        submitSongForm,
    } = props;

    const [id] = useState(selectedRow !== '' ? selectedRow.id : '');
    const [songId, setSongId] = useState(selectedRow !== '' ? selectedRow.song_id : '');
    const [songTitle, setSongTitle] = useState(selectedRow !== '' ? selectedRow.song_title : '');
    const [singerName1, setSingerName1] = useState(selectedRow !== '' ? selectedRow.singer_name_1 : '');
    const [uploadSource, setUploadSource] = useState(selectedRow !== '' ? selectedRow.upload_source : '');

    const [songIdError, setSongIdError] = useState('');
    const [songTitleError, setSongTitleError] = useState('');
    const [singerNameError, setSingerNameError] = useState('');

    const songIdChange = (event) => {
        setSongId(event.target.value);
    }

    const songTitleChange = (event) => {
        setSongTitle(event.target.value);
    }

    const singerName1Change = (event) => {
        setSingerName1(event.target.value);
    }

    const uploadSourceChange = (event) => {
        setUploadSource(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const songObject = {}
        songObject['id'] = id;
        songObject['song_id'] = songId;
        songObject['song_title'] = songTitle;
        songObject['singer_name_1'] = singerName1;
        songObject['upload_source'] = uploadSource;
        songObject['import_status'] = 'qualified';

        if (songId === '') {
            handleSongIdError();
        } else if (songTitle === '') {
            handleSongTitileEmpty();
        } else if (singerName1 === '') {
            handleSingerNameEmpty();
        } else if (songIdError === '' && songTitleError === '' && singerNameError === '') submitSongForm(songObject);
    };

    const handleSongIdError = event => {
        if (isNumbers(songId) === true) {
            if (songId.length === 6) {
                setSongIdError('')
            } else {
                setSongIdError('please fill in 6 digits')
            }
        } else {
            setSongIdError('cannot use character - must be 6 digits')
        }
    };

    const handleSongTitileEmpty = event => {
        if (songTitle.length > 0) {
            setSongTitleError('')
        } else {
            setSongTitleError('song title cannot be empty')
        }
    };

    const handleSingerNameEmpty = event => {
        if (singerName1.length > 0) {
            setSingerNameError('')
        } else {
            setSingerNameError('singer name 1 cannot be empty')
        }
    };

    return (
        <div>
            <Dialog open={openDialog} onClose={closeDialog} >
                <DialogTitle id="form-dialog-title" color="primary" sx={{ paddingTop: '30px' }}>
                    {title}
                </DialogTitle>
                <DialogContent sx={{ width: '500px' }}>
                    <Box component="form" onSubmit={handleSubmit} >
                        <div spellCheck="false" style={{ marginBottom: '10px' }} onBlur={handleSongIdError}>
                            <Typography sx={{ fontSize: '14px' }}>Song Id:</Typography>
                            <TextField
                                id="songId"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={songId}
                                onChange={songIdChange}
                                autoComplete='off'
                                helperText={<Typography component="span" sx={{ color: 'red', fontSize: '12px' }}>
                                    <Grid component="span">
                                        {songIdError}
                                    </Grid>
                                </Typography>}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderWidth: '1px'
                                        },
                                    }
                                }}
                            />
                        </div>
                        <div spellCheck="false" style={{ marginBottom: '10px' }} onBlur={handleSongTitileEmpty}>
                            <Typography sx={{ fontSize: '14px' }}>Song Title:</Typography>
                            <TextField
                                id="songTitle"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={songTitle}
                                onChange={songTitleChange}
                                autoComplete='off'
                                helperText={<Typography component="span" sx={{ color: 'red', fontSize: '12px' }}>
                                    <Grid component="span">
                                        {songTitleError}
                                    </Grid>
                                </Typography>}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderWidth: '1px'
                                        },
                                    }
                                }}
                            />
                        </div>
                        <div spellCheck="false" style={{ marginBottom: '10px' }} onBlur={handleSingerNameEmpty}>
                            <Typography sx={{ fontSize: '14px' }}>Singer Name 1:</Typography>
                            <TextField
                                id="singerName1"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={singerName1}
                                onChange={singerName1Change}
                                autoComplete='off'
                                helperText={<Typography component="span" sx={{ color: 'red', fontSize: '12px' }}>
                                    <Grid component="span">
                                        {singerNameError}
                                    </Grid>
                                </Typography>}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderWidth: '1px'
                                        },
                                    }
                                }}
                            />
                        </div>
                        <div spellCheck="false" style={{ marginBottom: '10px' }}>
                            <Typography sx={{ fontSize: '14px' }}>Singer Name 2:</Typography>
                            <TextField
                                id="uploadSource"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={uploadSource}
                                onChange={uploadSourceChange}
                                autoComplete='off'
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderWidth: '1px'
                                        },
                                    }
                                }}
                            />
                        </div>

                    </Box>
                </DialogContent>
                <DialogActions sx={{ pr: '14px', pb: '20px' }}>
                    <Button onClick={closeDialog} color="primary">Cancel</Button>
                    <Button form="companyForm" color="primary" type="submit" onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default CsvFormDialog;