import React, { useState } from 'react';
import {
    Box,
    TextField,
    InputAdornment,
    Button,
    Paper,
    Link,
    Grid,
    Container,
    Typography,
    Divider
} from '@mui/material/';

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Lock from '@mui/icons-material/Lock';


function MemberChangePassword(props) {
    const {
        changeMemberPasswordAction,
    } = props;

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const oldPasswordChanged = (event) => {
        setOldPassword(event.target.value);
    }

    const newPasswordChanged = (event) => {
        setNewPassword(event.target.value);
    }

    const confirmPasswordChanged = (event) => {
        setConfirmPassword(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        changeMemberPasswordAction({
            oldPassword: event.target['oldPassword'].value,
            newPassword: event.target['newPassword'].value,
            confirmPassword: event.target['confirmPassword'].value
        });
    };

    return (
        <div style={{
            background: '#b3e5fc',
            height: '100vh'
        }}>
            <Container maxWidth="lg">
                <Grid container sx={{
                    pt: { xs: '0px', md: '160px' },
                }}>
                    <Grid item xs={12} md={6} align="center" sx={{
                        margin: 'auto',
                        width: '50%',
                        padding: '10px',
                    }}>
                        <Typography
                            variant="h4"
                            gutterBottom
                        >
                            Welcome To VietK
                        </Typography>

                        <Typography
                            fontWeight='300'
                            mb={4}
                            sx={{
                                fontSize: { xs: 12, md: 21 },
                                paddingLeft: { xs: 2, sm: 10, md: 2, lg: 0 },
                                paddingRight: { xs: 2, sm: 10, md: 2, lg: 0 },
                            }}
                        >
                            We are committed to protecting your privacy. We will only use the
                            information that we collect about you lawfully in accordance with the Data
                            Protection Act.
                        </Typography>

                        <Typography
                            fontWeight='300'
                            sx={{
                                fontSize: { xs: 14, md: 21 },
                                mb: { xs: 4, md: 0 },
                                paddingLeft: { xs: 2, sm: 10, md: 2, lg: 0 },
                                paddingRight: { xs: 2, sm: 10, md: 2, lg: 0 },
                                display: { xs: 'none', md: 'block' },
                            }}
                        >
                            If you have any questions/comments about our privacy policy, you should
                            contact us at whoisthat@vietk.com.vn
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={1} align="center">

                    </Grid>

                    <Grid item xs={12} md={5} align="center">
                        <Paper
                            sx={{
                                m: '0px -4px',
                                p: { xs: '10px 20px', sm: '20px 20px', md: '30px 30px' },
                                maxWidth: 500,
                            }}
                        >
                            <Box sx={{
                                fontSize: { xs: '22px', md: '24px' },
                            }}>
                                Change Your Password
                            </Box>
                            <Box sx={{
                                fontSize: { xs: '12px', sm: '13px', md: '18px' },
                                marginTop: { xs: '8px', md: '18px' },
                            }}>
                                Please fill in all required fields to register your account.
                            </Box>

                            <form onSubmit={handleSubmit}>
                                <section>
                                    <div spellCheck="false">
                                        <TextField
                                            name="oldPassword"
                                            placeholder="Old Password"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            type="password"
                                            required={true}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <VpnKeyIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={oldPassword}
                                            onChange={oldPasswordChanged}
                                            sx={{
                                                mt: 1,
                                                '& svg': {
                                                    color: (theme) => theme.palette.grey[400],
                                                    fontSize: 22,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div spellCheck="false">
                                        <TextField
                                            name="newPassword"
                                            placeholder="New Password"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            type="password"
                                            required={true}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <VpnKeyIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={newPassword}
                                            onChange={newPasswordChanged}
                                            sx={{
                                                mt: 1,
                                                '& svg': {
                                                    color: (theme) => theme.palette.grey[400],
                                                    fontSize: 22,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div spellCheck="false">
                                        <TextField
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            type="password"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={confirmPassword}
                                            onChange={confirmPasswordChanged}
                                            sx={{
                                                mt: 1,
                                                '& svg': {
                                                    color: (theme) => theme.palette.grey[400],
                                                    fontSize: 22,
                                                },
                                            }}
                                        />
                                    </div>
                                </section>
                                <Box>
                                    <Button
                                        variant="contained"
                                        type="submit" fullWidth={true}
                                        sx={{ mt: 1, mb: { xs: 0, md: 2 } }}
                                    >
                                        Submit Change
                                    </Button>
                                </Box>
                                <Typography mb={0.3} mt={2} variant="body2">
                                    By registering, you accept our{" "}<Link href="#">Term</Link>{" "}and{" "}<Link href="#">Privacy Policy</Link>
                                </Typography>

                                <Divider sx={{ mb: '10px' }} />
                                <Box align='center' sx={{ fontSize: '16px' }}>
                                    Already have an account?{" "}
                                    <Box display="inline" fontWeight='500'>
                                        <Link href="/signin">Sign In</Link>
                                    </Box>{" "}
                                </Box>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default MemberChangePassword;