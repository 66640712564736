import React from "react";
import {
    Container,
    Box,
    Grid
} from '@mui/material/';
import { useSelector } from 'react-redux';

import HeaderActivity from '../header/HeaderActivity';

export default function HomePage() {
    const display = useSelector((state) => state.main.display);
    //console.log('display: '+display);

    //localStorage.removeItem("account");
    //localStorage.removeItem("member");

    return (
        <div>
            <Box sx={{
                height: { md: '65vh', xs: 'auto' },
                width: "auto",
                backgroundImage: 'url("../images/home_background.jpg")',
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
            }}>
                <Container maxWidth="xl">
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <Box sx={{
                                '& img': {
                                    width: { md: '50%', xs: '70%' },
                                    float: 'left'
                                }
                            }}>
                                <img src="/images/karaoke_logo.png" alt="crypto dashboard" />
                            </Box>
                        </Grid>
                        <Grid item xs={10} justifyContent="flex-end">
                            <Box sx={{
                                float: 'right',
                                marginTop: { md: '6px', xs: '0px' },
                            }}>
                                <HeaderActivity display={display}/>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xl={6} lg={6} md={6} xs={5} sx={{
                            '& img': {
                                width: { md: 'auto', xs: '45%' },
                            },
                            textAlign: 'center',
                            //padding: '10px 0px',
                            paddingBottom: '10px',
                        }}>
                            <img src="/images/singer.gif" alt="crypto dashboard" />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} xs={7}>
                            <Box sx={{
                                marginTop: { xs: '0px', md: '20px' },
                                fontWeight: { xs: 500, md: 600 },
                                color: "#fdd835",
                                fontSize: { lg: '55px', md: '30px', xs: '19px' }
                            }}>
                                Nơi mọi người thể hiện bản thân, truyền đạt cảm xúc, và suy nghĩ của mình thông qua âm nhạc 
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <div>
                <Container maxWidth="lg">
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: { md: '30px', xs: '4px' },
                        }}
                    >
                        <Grid item xl={4} lg={4} md={4} xs={12}>
                            <div style={{
                                width: "auto",
                                height: '160px',
                                backgroundImage: 'url("../images/1.jpg")',
                                backgroundSize: "100%",
                                backgroundRepeat: "no-repeat"
                            }}>
                                <Grid container>
                                    <Grid item lg={7} md={6.5} xs={6.5}>
                                        <Box sx={{
                                            padding: '10px 10px',
                                            fontSize: { md: '28px', xs: '23px' },
                                            fontWeight: 600,
                                            color: '#455a64',
                                        }}>
                                            Web Karaoke
                                        </Box>
                                        <Box sx={{
                                            padding: '0px 10px',
                                            fontSize: '12px',
                                            color: '#455a64',
                                        }}>
                                            Bạn có thể hát trên bất kỳ smart TV nào cho dù ở nhà, khách sạn, hay trong mọi cuộc tụ tập vui chơi
                                        </Box>
                                    </Grid>
                                    <Grid item lg={5} md={5.5} xs={5.5}></Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xl={4} lg={4} md={4} xs={12} >
                            <div style={{
                                width: "auto",
                                height: "160px",
                                backgroundImage: 'url("../images/2.jpg")',
                                backgroundSize: "100%",
                                backgroundRepeat: "no-repeat"
                            }}>
                                <Grid container >
                                    <Grid item lg={7} md={6.5} xs={6.5}>
                                        <Box sx={{
                                            padding: '10px 10px',
                                            fontSize: { md: '28px', xs: '23px' },
                                            fontWeight: 600,
                                            color: '#455a64',
                                        }}>
                                            Cloud Karaoke
                                        </Box>
                                        <Box sx={{
                                            padding: '0px 10px',
                                            fontSize: '12px',
                                            color: '#455a64',
                                        }}>
                                            Tương thích với các đầu phát android cao cấp đang có mặt trên thị trường
                                        </Box>
                                    </Grid>
                                    <Grid item lg={5} md={5.5} xs={5.5}></Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xl={4} lg={4} md={4} xs={12} >
                            <div style={{
                                width: "auto",
                                height: "160px",
                                backgroundImage: 'url("../images/3.jpg")',
                                backgroundSize: "100%",
                                backgroundRepeat: "no-repeat"
                            }}>
                                <Grid container >
                                    <Grid item lg={7} md={6.5} xs={6.5}>
                                        <Box sx={{
                                            padding: '10px 10px',
                                            fontSize: { md: '28px', xs: '23px' },
                                            fontWeight: 600,
                                            color: '#455a64',
                                        }}>
                                            Pro Karaoke
                                        </Box>
                                        <Box sx={{
                                            padding: '0px 10px',
                                            fontSize: '12px',
                                            color: '#616161',
                                        }}>
                                            Sở hữu kho bài hát khổng lồ nhờ dung lượng chứa bài hát lên tới 6TB được cập nhật liên tục
                                        </Box>
                                    </Grid>
                                    <Grid item lg={5} md={5.5} xs={5.5}></Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                    <Box sx={{
                        marginTop: '30px',
                        fontSize: { md: '55px', xs: '25px' },
                        fontWeight: 600,
                        color: '#455a64',
                        textAlign: 'center'
                    }}>
                        Giải pháp Karaoke chuyên nghiệp
                    </Box>
                </Container>
            </div>
        </div>
    );
} 