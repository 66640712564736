import { configureStore } from '@reduxjs/toolkit'

import mainSlice from "./mainSlice";
import memberSlice from "./memberSlice";
import karaokeSlice from "./karaokeSlice";
import songSlice from "./songSlice";
import uploadSlice from "./uploadSlice";
import downloadSlice from "./downloadSlice";
import importSlice from "./importSlice";
import deviceSlice from "./deviceSlice";

export const store = configureStore({
  reducer: {
    main: mainSlice,
    member: memberSlice,
    karaoke: karaokeSlice,
    song: songSlice,
    import: importSlice,
    upload: uploadSlice,
    download: downloadSlice,
    device: deviceSlice,
  },
})