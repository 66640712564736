import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authHeader from "../components/token/auth-header";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT + "/api/";

const getApiParams = (karaokePage, karaokeRowsPerPage, searchColumn, searchContent, orderBy, order) => {
    let params = {};
    params["karaokePage"] = karaokePage;
    params["karaokeRowsPerPage"] = karaokeRowsPerPage;
    params["searchColumn"] = searchColumn;
    params["searchContent"] = searchContent;
    params["orderBy"] = orderBy;
    params["order"] = order;
    return params;
}

const getSingerFetchApiParams = (karaokePage, karaokeRowsPerPage) => {
    let params = {};
    params["karaokePage"] = karaokePage;
    params["karaokeRowsPerPage"] = karaokeRowsPerPage;
    return params;
}

const getSingerSearchApiParams = (karaokePage, karaokeRowsPerPage, searchColumn, searchContent) => {
    let params = {};
    params["karaokePage"] = karaokePage;
    params["karaokeRowsPerPage"] = karaokeRowsPerPage;
    params["searchColumn"] = searchColumn;
    params["searchContent"] = searchContent;
    return params;
}

const fetchYoutubeApiParams = (karaokePage, karaokeRowsPerPage, searchContent) => {
    let params = {};
    params["karaokePage"] = karaokePage;
    params["karaokeRowsPerPage"] = karaokeRowsPerPage;
    params["searchContent"] = searchContent;
    return params;
}

const initialState = {
    isLoading: false,
    hasError: false,
    karaokeList: [],
    queueList: [],
    favoriteList: [],
    searchColumn: 'song_title',
    searchContent: '',
    orderBy: 'song_title',
    order: 'asc',
    karaokePage: 0,
    karaokeRowsPerPage: 5,
    karaokeTotalCount: 0,

    videoControllerDisplay: '',
    isKaraokePlay: false,
    pauseKaraoke: false,
    vocalActive: 'dim',
    fullScreenOn: false,
    currentVolume: 0.5,
    songRepeat: false,
}

export const fetchKaraokeListAction = createAsyncThunk(
    'karaoke/fetchKaraokeListAction',
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const karaokePage = store.karaoke.karaokePage;
        const searchColumn = store.karaoke.searchColumn;
        const searchContent = '';
        const karaokeRowsPerPage = store.karaoke.karaokeRowsPerPage;
        const orderBy = store.karaoke.orderBy;
        const order = store.karaoke.order;

        const params = getApiParams(karaokePage, karaokeRowsPerPage, searchColumn, searchContent, orderBy, order);
        console.log(JSON.stringify(params))

        const response = await axios.get(BASE_URL + "fetch_karaoke_list", { params, headers: authHeader() })
        return response.data
    }
)

export const fetchSingerListAction = createAsyncThunk(
    'karaoke/fetchSingerListAction',
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const karaokePage = store.karaoke.karaokePage;
        const karaokeRowsPerPage = store.karaoke.karaokeRowsPerPage;

        const params = getSingerFetchApiParams(karaokePage, karaokeRowsPerPage);

        const response = await axios.get(BASE_URL + "fetch_singer_list", { params, headers: authHeader() })
        return response.data
    }
)

export const fetchSongsBelongSingerAction = createAsyncThunk(
    'karaoke/fetchSongsBelongSingerAction',
    async (singerName, thunkAPI) => {
        const store = thunkAPI.getState();
        const karaokePage = store.karaoke.karaokePage;
        const searchColumn = store.karaoke.searchColumn;
        const searchContent = singerName;
        const karaokeRowsPerPage = store.karaoke.karaokeRowsPerPage;
        const orderBy = store.karaoke.orderBy;
        const order = store.karaoke.order;

        const params = getApiParams(karaokePage, karaokeRowsPerPage, searchColumn, searchContent, orderBy, order);

        const response = await axios.get(BASE_URL + "fetch_karaoke_list", { params, headers: authHeader() })
        return response.data
    }
)

export const searchSingerNameAction = createAsyncThunk(
    'karaoke/searchSingerNameAction',
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const karaokePage = store.karaoke.karaokePage;
        const karaokeRowsPerPage = store.karaoke.karaokeRowsPerPage;
        const searchColumn = store.karaoke.searchColumn;
        const searchContent = store.karaoke.searchContent;

        const params = getSingerSearchApiParams(karaokePage, karaokeRowsPerPage, searchColumn, searchContent);

        const response = await axios.get(BASE_URL + "search_singer_name", { params, headers: authHeader() })
        return response.data
    }
)

export const fetchYoutubeListAction = createAsyncThunk(
    'karaoke/fetchYoutubeListAction',
    async (search_text, thunkAPI) => {
        console.log('search_text: '+search_text);

        const store = thunkAPI.getState();
        const karaokePage = store.karaoke.karaokePage;
        const karaokeRowsPerPage = store.karaoke.karaokeRowsPerPage;
        const searchContent = search_text;

        const params = fetchYoutubeApiParams(karaokePage, karaokeRowsPerPage, searchContent);

        const response = await axios.get(BASE_URL + "fetch_youtube_list", { params, headers: authHeader() })
        return response.data
    }
)

export const fetchSoundCloudListAction = createAsyncThunk(
    'karaoke/fetchSoundCloudListAction',
    async (search_text, thunkAPI) => {
        console.log('search_text: '+search_text);

        const store = thunkAPI.getState();
        const karaokePage = store.karaoke.karaokePage;
        const karaokeRowsPerPage = store.karaoke.karaokeRowsPerPage;
        const searchContent = search_text;

        const params = fetchYoutubeApiParams(karaokePage, karaokeRowsPerPage, searchContent);

        const response = await axios.get(BASE_URL + "fetch_soundcloud_list", { params, headers: authHeader() })
        return response.data
    }
)

export const fetchMixCloudListAction = createAsyncThunk(
    'karaoke/fetchMixCloudListAction',
    async (search_text, thunkAPI) => {
        console.log('search_text: '+search_text);

        const store = thunkAPI.getState();
        const karaokePage = store.karaoke.karaokePage;
        const karaokeRowsPerPage = store.karaoke.karaokeRowsPerPage;
        const searchContent = search_text;

        const params = fetchYoutubeApiParams(karaokePage, karaokeRowsPerPage, searchContent);

        const response = await axios.get(BASE_URL + "fetch_mixcloud_list", { params, headers: authHeader() })
        return response.data
    }
)

export const karaokeSlice = createSlice({
    name: 'karaoke',
    initialState,
    reducers: {
        setKaraokePageAction: (state, action) => {
            state.karaokePage = action.payload
        },
        setKaraokeRowsPerPageAction: (state, action) => {
            state.karaokeRowsPerPage = action.payload
        },
        setSearchColumnAction: (state, action) => {
            state.searchColumn = action.payload
        },
        setSearchContentAction: (state, action) => {
            state.searchContent = action.payload
        },
        setQueueListAction: (state, action) => {
            state.queueList = action.payload
        },
        emptyQueueListAction: (state) => {
            state.queueList = []
        },
        
        setVideoControllerDisplayAction: (state, action) => {
            state.videoControllerDisplay = action.payload
        },
        setPauseKaraokeAction: (state, action) => {
            state.pauseKaraoke = action.payload
        },
        setVocalActiveAction: (state, action) => {
            state.vocalActive = action.payload
        },
        setFullscreenOnAction: (state, action) => {
            state.fullScreenOn = action.payload
        },
        setCurrentVolumeAction: (state, action) => {
            state.currentVolume = action.payload
        },
        setSongRepeatAction: (state, action) => {
            state.songRepeat = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchKaraokeListAction.pending, (state) => {
                console.log("----------> pending");
                state.karaokeList = [];
                state.isLoading = true;
            })
            .addCase(fetchKaraokeListAction.fulfilled, (state, action) => {
                //console.log(JSON.stringify(action.payload))

                state.searchColumn = 'song_title';
                state.searchContent = '';
                state.karaokeList = action.payload.dataList;
                state.karaokeTotalCount = action.payload.rowCount;
                state.isLoading = false;
            })
            .addCase(fetchSingerListAction.fulfilled, (state, action) => {
                //console.log(JSON.stringify(action.payload))

                state.searchColumn = 'singer_name_1'
                state.searchContent = ''
                state.karaokeList = action.payload.dataList
                state.karaokeTotalCount = action.payload.totalPages
            })
            .addCase(fetchSongsBelongSingerAction.fulfilled, (state, action) => {
                //console.log(JSON.stringify(action.payload))

                state.searchColumn = 'song_title'
                state.searchContent = ''
                state.karaokeList = action.payload.dataList
                state.karaokeTotalCount = action.payload.totalPages
            })
            .addCase(searchSingerNameAction.fulfilled, (state, action) => {
                //console.log(JSON.stringify(action.payload))

                state.searchColumn = 'singer_name_1'
                state.searchContent = ''
                state.karaokeList = action.payload.dataList
                state.karaokeTotalCount = action.payload.totalPages
            })
            .addCase(fetchYoutubeListAction.pending, (state) => {
                console.log("----------> youtube loading");
                state.karaokeList = [];
                state.isLoading = true;
            })
            .addCase(fetchYoutubeListAction.fulfilled, (state, action) => {               
                state.searchContent = '';
                state.karaokeList = action.payload.dataList;
                state.karaokeTotalCount = action.payload.totalPages;
                state.isLoading = false;
            })
            .addCase(fetchSoundCloudListAction.pending, (state) => {
                console.log("----------> soundcloud loading");
                state.karaokeList = [];
                state.isLoading = true;
            })
            .addCase(fetchSoundCloudListAction.fulfilled, (state, action) => {
                state.searchContent = '';
                state.karaokeList = action.payload.dataList;
                state.karaokeTotalCount = action.payload.totalPages;
                state.isLoading = false;
            })
            .addCase(fetchMixCloudListAction.pending, (state) => {
                console.log("----------> mixcloud loading");
                state.karaokeList = [];
                state.isLoading = true;
            })
            .addCase(fetchMixCloudListAction.fulfilled, (state, action) => {                
                state.searchContent = '';
                state.karaokeList = action.payload.dataList;
                state.karaokeTotalCount = action.payload.totalPages;
                state.isLoading = false;
            })
    },
})

export const {
    setKaraokePageAction,
    setKaraokeRowsPerPageAction,
    setSearchColumnAction,
    setSearchContentAction,
    setQueueListAction,
    emptyQueueListAction,

    setVideoControllerDisplayAction,
    setPauseKaraokeAction,
    setVocalActiveAction,
    setFullscreenOnAction,
    setCurrentVolumeAction,
    setSongRepeatAction
} = karaokeSlice.actions

export default karaokeSlice.reducer;