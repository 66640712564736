import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material/';

function DeleteConfirmDialog(props) {
    const {
        openConfirmDelete,
        closeConfirmDelete,
        deleteRowHandle
    } = props;

    return (
        <div>
            <Dialog
                open={openConfirmDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ zIndex: 1600 }}
            >
                <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete selected device?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmDelete} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={deleteRowHandle} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default DeleteConfirmDialog;