import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
    update_main_display_action
} from "../redux/mainSlice";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT + "/api/";
console.log('BASE_URL: '+BASE_URL);

const initialState = {
    memberAuthOpenAlert: false,
    memberAuthMessage: '',
    status: '',
    loading: false,
}

export const registerNewMemberAction = createAsyncThunk(
    "member/registerNewMemberAction",
    async ({ memberName, memberMobile, memberEmail, memberPassword }, { dispatch }) => {
        const response = await axios.post(BASE_URL + "register_new_member", { memberName, memberMobile, memberEmail, memberPassword })

        //console.log('=====> registerNewMemberAction: ' + JSON.stringify(response.data))

        if (response.data.status === 'signup_ok') {
            dispatch(update_main_display_action('member_sign_in'));
        }
        return response.data
    }
);

export const loginCurrentMemberAction = createAsyncThunk(
    'member/loginCurrentMemberAction',
    async ({ memberMobile, memberPassword }) => {

        const response = await axios.post(BASE_URL + "login_current_member", { memberMobile, memberPassword })
        return response.data
    }
);

export const changeMemberPasswordAction = createAsyncThunk(
    'member/changeMemberPasswordAction',
    async ({ oldPassword, newPassword, confirmPassword }) => {
        const response = await axios.post(BASE_URL + "change_member_password", { oldPassword, newPassword, confirmPassword })
        return response.data
    }
)

export const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        memberLogoutAction: () => {
            localStorage.removeItem("member");
            //window.location.reload(false);
        },
        openMemberAuthAlertAction: (state) => {
            state.memberAuthOpenAlert = true
        },
        closeMemberAuthAlertAction: (state) => {
            state.memberAuthOpenAlert = false;
        },
        setAuthStageAction: (state, action) => {
            state.authStage = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerNewMemberAction.fulfilled, (state, { payload }) => {
                //console.log('=====> registerNewMemberAction: ' + JSON.stringify(payload))

                state.loading = false
                state.memberAuthOpenAlert = true
                state.memberAuthMessage = payload.message
                state.status = payload.status
            })
            .addCase(loginCurrentMemberAction.fulfilled, (state, { payload }) => {
                state.loading = false

                console.log(JSON.stringify(payload))

                if (payload.status === 'signin_ok') {
                    localStorage.setItem('member', JSON.stringify(payload.accessToken));
                    window.location.reload(false);
                } else {
                    state.openAlert = true
                    state.message = payload.message
                }
            })
    },

})

export const {
    memberLogoutAction,
    openMemberAuthAlertAction,
    closeMemberAuthAlertAction
} = memberSlice.actions


export default memberSlice.reducer;