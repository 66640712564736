const category = [
    { label: 'Song Id', value: 'song_id', name: 'Category' },
    { label: 'Song Title', value: 'song_title', name: 'Province or City' },
    { label: 'Singer Name 1', value: 'singer_name_1', name: 'Company Nation' },
    { label: 'Singer Name 2', value: 'singer_name_2', name: 'Business Sector' }
];

const karaoke = [
    { label: 'Tìm kiếm bài hát karaoke', value: 'karaoke', name: 'localsong' },
    { label: 'Tìm kiếm ca sỹ', value: 'singer', name: 'singername' },
    { label: 'Tìm kiếm trên Youtube', value: 'youtube', name: 'youtube' },
    { label: 'Tìm kiếm trên SoundCloud', value: 'soundcloud', name: 'soundcloud' },
    { label: 'Tìm kiếm trên MixCloud', value: 'mixcloud', name: 'mixcloud' }
];

const device = [
    { label: 'Device Serial Number', value: 'device_serial', name: 'Device Serial Number' },
    { label: 'Device Name', value: 'device_name', name: 'Device Name' },
    { label: 'Owner Mobile', value: 'owner_mobile', name: 'Owner Mobile' },
    { label: 'Owner Location', value: 'owner_location', name: 'Owner Location' }
];

export { category, karaoke, device };