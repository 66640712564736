import * as React from 'react';
import {
    Box,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
} from '@mui/material/';

import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useDispatch } from 'react-redux';
import {
    addNewRowsAction,
    updateSelectedRowsAction,
    deleteSelectedRowsAction
} from "../../../redux/importSlice"

import CsvFormDialog from './CsvFormDialog';
import DeleteConfirmDialog from '../DeleteConfirmDialog';

export default function EnhancedTableToolbar(props) {
    const {
        csvList,
        selected,
        setSelected,
    } = props;

    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);

    //console.log('selected: '+selected.length);

    const addNewRowHandle = () => {
        setSelected([]);
        setOpenDialog(true);
    };

    const editRowHandle = () => {
        setOpenDialog(true);
    };

    const deleteRowHandle = () => {
        dispatch(deleteSelectedRowsAction(selected));
        setSelected([]);
        setOpenConfirmDelete(false);
    };

    const getModalTitle = () => {
        let modalTitle = '';
        selected.length > 0 ? modalTitle = 'Update Selected Song' : modalTitle = 'Add New Song';
        return (modalTitle);
    }

    const convertSelectedArray = () => {
        const convertedObj = csvList.filter(value => selected.includes(value.song_id))[0]
        return convertedObj;
    }

    const submitSongForm = (payload) => {
        getModalTitle() === 'Add New Song' ? dispatch(addNewRowsAction(payload)) : dispatch(updateSelectedRowsAction(payload));

        setSelected([]);
        setOpenDialog(false);
    };

    return (
        <div>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(selected.length > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {selected.length > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {selected.length} selected
                    </Typography>
                ) : (
                    <Box sx={{ flex: '1 1 100%' }}>
                        Total {csvList.length} songs
                    </Box>
                )}

                {selected.length > 0 ? (
                    <div style={{
                        display: 'flex',
                    }}>
                        <div style={{ marginRight: '12px' }}>
                            <Tooltip title="Delete" >
                                <IconButton onClick={() => setOpenConfirmDelete(true)}>
                                    <DeleteIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div style={{ marginRight: '10px', display: selected.length > 1 ? 'none' : 'block' }} >
                            <Tooltip title="Edit" >
                                <IconButton onClick={editRowHandle} >
                                    <BuildCircleIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ) : null}

                <Tooltip title="Add">
                    <IconButton onClick={addNewRowHandle} >
                        <AddCircleIcon color='primary' />
                    </IconButton>
                </Tooltip>
            </Toolbar>
            {openDialog && <CsvFormDialog
                openDialog={openDialog}
                closeDialog={() => setOpenDialog(false)}
                title={getModalTitle()}
                selectedRow={selected.length > 0 ? convertSelectedArray() : ''}
                lastCsvListIndex={csvList.length+1}
                submitSongForm={submitSongForm}
            />}
            {openConfirmDelete && <DeleteConfirmDialog
                openConfirmDelete={openConfirmDelete}
                closeConfirmDelete={() => setOpenConfirmDelete(false)}
                deleteRowHandle={() => deleteRowHandle()}
            />}
        </div>
    );
}