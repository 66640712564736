import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authHeader from "../components/token/auth-header";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT + "/api/";

const getApiParams = (page, rowsPerPage, searchColumn, searchContent, orderBy, order) => {
    let params = {};
    params["page"] = page;
    params["rowsPerPage"] = rowsPerPage;
    params["searchColumn"] = searchColumn;
    params["searchContent"] = searchContent;
    params["orderBy"] = orderBy;
    params["order"] = order;
    return params;
}

const initialState = {
    isLoading: false,
    hasError: false,
    deviceList: [],
    rowsTotalCount: 0,
    page: 0,
    rowsPerPage: 5,
    searchColumn: 'device_serial',
    searchContent: '',
    orderBy: 'id',
    order: 'asc'
}

export const fetchDeviceListAction = createAsyncThunk(
    'device/fetchDeviceListAction',
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.device.page;
        const searchColumn = store.device.searchColumn;
        const searchContent = store.device.searchContent;
        const rowsPerPage = store.device.rowsPerPage;
        const orderBy = store.device.orderBy;
        const order = store.device.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);

        const response = await axios.get(BASE_URL + "fetch_device_list", { params, headers: authHeader() })
        return response.data
    }
)

export const registerNewDeviceAction = createAsyncThunk(
    'device/registerNewDeviceAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.device.page;
        const searchColumn = store.device.searchColumn;
        const searchContent = store.device.searchContent;
        const rowsPerPage = store.device.rowsPerPage;
        const orderBy = store.device.orderBy;
        const order = store.device.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);

        const response = await axios.post(BASE_URL + 'register_new_device', data, { params, headers: authHeader() })

        return response.data
    }
)

export const updateCurrentDeviceAction = createAsyncThunk(
    'device/updateCurrentDeviceAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.device.page;
        const searchColumn = store.device.searchColumn;
        const searchContent = store.device.searchContent;
        const rowsPerPage = store.device.rowsPerPage;
        const orderBy = store.device.orderBy;
        const order = store.device.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);

        //console.log('updateCurrentDeviceAction: ' + JSON.stringify(data));

        const response = await axios.put(BASE_URL + `update_current_device/${data.id}`, data, { params, headers: authHeader() })

        return response.data
    }
)

export const deleteCurrentDeviceAction = createAsyncThunk(
    'device/deleteCurrentDeviceAction',
    async (id, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.device.page;
        const searchColumn = store.device.searchColumn;
        const searchContent = store.device.searchContent;
        const rowsPerPage = store.device.rowsPerPage;
        const orderBy = store.device.orderBy;
        const order = store.device.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        const response = await axios.delete(BASE_URL + `delete_current_device/${id}`, { params, headers: authHeader() })
 
        return response.data
    }
)

export const sortDeviceColumnFieldAction = createAsyncThunk(
    'device/sortColumnFieldAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.device.page;
        const searchColumn = store.device.searchColumn;
        const searchContent = store.device.searchContent;
        const rowsPerPage = store.device.rowsPerPage;

        const { orderBy, order } = data;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);

        const response = await axios.get(BASE_URL + "fetch_device_list", { params, headers: authHeader() })
        return response.data
    }
)

export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        setPageAction: (state, action) => {
            state.page = action.payload
        },
        setRowsPerPageAction: (state, action) => {
            state.rowsPerPage = action.payload
        },
        setSearchColumnAction: (state, action) => {
            state.searchColumn = action.payload
        },
        setSearchContentAction: (state, action) => {
            state.searchContent = action.payload
        },
        resetDeviceSearchAction: (state) => {
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerNewDeviceAction.fulfilled, (state, action) => {
                //console.log('registerNewDeviceAction: '+JSON.stringify(action.payload))

                state.deviceList = action.payload.dataList
                state.rowsTotalCount = action.payload.rowCount
                state.searchContent = action.payload.searchContent
                state.orderBy = action.payload.orderBy
                state.order = action.payload.order

            })
            .addCase(fetchDeviceListAction.fulfilled, (state, action) => {
                //console.log('fetchDeviceListAction: '+JSON.stringify(action.payload))
                
                state.deviceList = action.payload.dataList
                state.rowsTotalCount = action.payload.rowCount
                //state.searchContent = action.payload.searchContent
                state.searchContent = ''
                state.orderBy = action.payload.orderBy
                state.order = action.payload.order
            })
            .addCase(deleteCurrentDeviceAction.fulfilled, (state, action) => {
                //console.log('deleteCurrentDeviceAction: '+JSON.stringify(action.payload))

                state.deviceList = action.payload.dataList
                state.rowsTotalCount = action.payload.rowCount
                state.searchContent = action.payload.searchContent
                state.orderBy = action.payload.orderBy
                state.order = action.payload.order
            })
            .addCase(updateCurrentDeviceAction.fulfilled, (state, action) => {
                //console.log('updateCurrentDeviceAction: '+JSON.stringify(action.payload))

                state.deviceList = action.payload.dataList
                state.rowsTotalCount = action.payload.rowCount
                state.searchContent = action.payload.searchContent
                state.orderBy = action.payload.orderBy
                state.order = action.payload.order
            })
    },
})

export const {
    setPageAction,
    setRowsPerPageAction,
    setSearchColumnAction,
    setSearchContentAction,
    resetDeviceSearchAction
} = deviceSlice.actions

export default deviceSlice.reducer;