import React, { useState } from 'react';
import {
    Box,
    TextField,
    InputAdornment,
    Button,
    Paper,
    Link,
    Grid,
    Container,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider
} from '@mui/material/';

import Lock from '@mui/icons-material/Lock';
import PhoneAndroid from '@mui/icons-material/PhoneAndroid';

function MemberSignUp(props) {
    const {
        memberAuthMessage,
        memberAuthOpenAlert,
        closeMemberAuthAlertAction,
        registerNewMemberAction,
        update_main_display_action
    } = props;

    const [memberName, setMemberName] = useState('');
    const [memberMobile, setMemberMobile] = useState('');
    const [memberEmail, setMemberEmail] = useState('');
    const [memberPassword, setMemberPassword] = useState('');

    const memberNameChanged = (event) => {
        setMemberName(event.target.value);
    }

    const memberMobileChanged = (event) => {
        setMemberMobile(event.target.value);
    }

    const memberEmailChanged = (event) => {
        setMemberEmail(event.target.value);
    }


    const memberPasswordChanged = (event) => {
        setMemberPassword(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        registerNewMemberAction({
            memberName: event.target['memberName'].value,
            memberMobile: event.target['memberMobile'].value,
            memberEmail: event.target['memberEmail'].value,
            memberPassword: event.target['memberPassword'].value
        });
    };

    const closeDialogHandler = () => {
        setMemberName('');
        setMemberEmail('');
        setMemberMobile('');
        setMemberPassword('');
        closeMemberAuthAlertAction();
    };

    return (
        <div>
            <Dialog
                open={memberAuthOpenAlert}
                onClose={closeDialogHandler}
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ textAlign: 'center' }}>
                        Account Service
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ textAlign: 'center' }}>
                            {memberAuthMessage}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialogHandler} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Container maxWidth="lg">
                <Grid container sx={{
                    pt: { xs: '20px', md: '160px' },
                }}>
                    <Grid item xs={0} md={6} lg={5} align="center">

                    </Grid>
                    <Grid item xs={0} md={0} lg={2} align="center">

                    </Grid>
                    <Grid item xs={12} md={5} lg={5} align="center">
                        <Paper
                            sx={{
                                m: '0px -4px',
                                p: { xs: '10px 20px', sm: '20px 20px', md: '30px 30px' },
                                maxWidth: 500,
                                background: '#eeeeee'
                            }}
                        >
                            <Box sx={{
                                fontSize: { xs: '22px', md: '24px' },
                            }}>
                                Đăng ký tài khoản
                            </Box>
                            <Box sx={{
                                fontSize: { xs: '12px', sm: '13px', md: '18px' },
                                paddingTop: { xs: '8px', md: '18px' },
                            }}>
                                Điền vào tất cả các trường.
                            </Box>
                            <form onSubmit={handleSubmit}>
                                <section>
                                    <div spellCheck="false">
                                        <TextField
                                            name="memberName"
                                            placeholder="Tên đầy đủ của bạn"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            type="text"
                                            required={true}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneAndroid />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={memberName}
                                            onChange={memberNameChanged}
                                            sx={{
                                                mt: 1,
                                                '& svg': {
                                                    color: (theme) => theme.palette.grey[400],
                                                    fontSize: 20,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div spellCheck="false">
                                        <TextField
                                            name="memberMobile"
                                            placeholder="Số điện thoại di động"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            type="phone"
                                            required={true}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneAndroid />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={memberMobile}
                                            onChange={memberMobileChanged}
                                            sx={{
                                                mt: 1,
                                                '& svg': {
                                                    color: (theme) => theme.palette.grey[400],
                                                    fontSize: 20,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div spellCheck="false">
                                        <TextField
                                            name="memberEmail"
                                            placeholder="Địa chỉ email"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            type="email"
                                            required={true}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneAndroid />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={memberEmail}
                                            onChange={memberEmailChanged}
                                            sx={{
                                                mt: 1,
                                                '& svg': {
                                                    color: (theme) => theme.palette.grey[400],
                                                    fontSize: 20,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div spellCheck="false">
                                        <TextField
                                            name="memberPassword"
                                            placeholder="Mật khẩu"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            type="password"
                                            required={true}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={memberPassword}
                                            onChange={memberPasswordChanged}
                                            sx={{
                                                mt: 1,
                                                '& svg': {
                                                    color: (theme) => theme.palette.grey[400],
                                                    fontSize: 20,
                                                },
                                            }}
                                        />
                                    </div>
                                </section>
                                <Box>
                                    <Button
                                        variant="contained"
                                        type="submit" fullWidth={true}
                                        sx={{ mt: 1, mb: { xs: 0, md: 2 } }}
                                    >
                                        Đăng Ký
                                    </Button>
                                </Box>
                                <Typography mb={0.3} mt={2} variant="body2">
                                    Đăng ký cũng đồng nghĩa bạn đã chấp nhận {" "}<Link href="#">điều khoản</Link> và {" "}<Link href="#">chính sách bảo mật</Link> của chúng tôi
                                </Typography>

                                <Divider sx={{ mb: '20px' }} />
                                <Box align='center' sx={{ fontSize: '12px' }}>
                                    Bạn đã có tài khoản{" "}
                                    <Box fontWeight='500'>
                                        <Link
                                            href="#"
                                            underline="hover"
                                            sx={{ fontSize: '16px' }}
                                            onClick={() => { update_main_display_action('member_sign_in') }}
                                        >
                                            Đăng nhập tài khoản
                                        </Link>
                                    </Box>{" "}
                                </Box>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default MemberSignUp;