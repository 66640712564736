import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
//import authHeader from "../components/token/auth-header";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT + "/api/";

const initialState = {
    isLoading: false,
    hasError: false,
    uploadType: 'video_upload',
    csvList: [],
    badList: [],
    importStatus: '',
    csvListLength: 0,
    importedCsvCount: 0,
    allCount: 0
}

function findIndexByItem(array, object) {
    const index = array.findIndex(item => {
        return item.id === object.id;
    });
    return index;
}

function addItemInArray(array, object) {
    const newCsvList = array;
    newCsvList.push(object);
    return newCsvList
}
 
function updateItemInArray(array, object) {
    const index = findIndexByItem(array, object);
    const newCsvList = array;
    newCsvList[index] = object;
    return newCsvList
}

function removeFromArray(originalList, removeList) {
    const reverseRemoveList = removeList.sort(function (a, b) { return b - a; });
    return originalList.filter(value => !reverseRemoveList.includes(value.song_id));
}

export const importCsvDataAction = createAsyncThunk(
    "import/importCsvDataAction",
    async ({ index, list }, { dispatch }) => {
        const response = await axios.post(BASE_URL + "import_raw_csv_list", list[index]);

        if (response?.status === 200) {
            index += 1;

            if (index < list.length) {
                dispatch(importCsvDataAction({ index, list }));
            }
        }
        return response.data
    }
);

export const importSlice = createSlice({
    name: 'import',
    initialState,
    reducers: {
        setCsvDataListAction: (state, action) => {
            state.uploadType = 'csv_upload'
            state.csvList = action.payload
            state.csvListLength = state.csvList.length
            state.importStatus = state.csvList.length > 0 ? 'ready' : ''
        },       
        addNewRowsAction: (state, action) => {
            state.csvList = addItemInArray(state.csvList, action.payload)
        },
        updateSelectedRowsAction: (state, action) => {
            state.csvList = updateItemInArray(state.csvList, action.payload)
        },
        deleteSelectedRowsAction: (state, action) => {
            state.csvList = removeFromArray(state.csvList, action.payload)
            state.csvListLength = state.csvList.length
        },
        resetCsvDataImportAction: (state) => {
            state.uploadType = 'video_upload'
            state.csvList = []
            state.badList = []
            state.csvListLength = 0
            state.importedCsvCount = 0
            state.importedCsvCount = 0
            state.allCount = 0
            state.importStatus = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(importCsvDataAction.fulfilled, (state, action) => {
                state.importStatus = 'process'
                state.allCount += 1

                const index = state.csvList.findIndex(
                    (item) => item.video_id === action.payload.video_id
                )

                if (index > -1) {
                    if (action.payload.message === 'imported') {
                        state.importedCsvCount += 1
                    } else {
                        state.csvList[index].import_status = action.payload.message
                        state.badList.push(state.csvList[index])
                    }
                }

                state.csvList.splice(index, 1);

                if (state.allCount === state.csvListLength && state.badList.length > 0) {
                    state.csvList = state.badList;
                }

                if (state.allCount === state.csvListLength) state.importStatus = 'complete';
            })

    }
})

export const {
    setCsvDataListAction,
    addNewRowsAction,
    updateSelectedRowsAction,
    deleteSelectedRowsAction,
    resetCsvDataImportAction
} = importSlice.actions


export default importSlice.reducer;