import React, { useRef, useState } from 'react';
import {
    Box,
    Avatar,
    LinearProgress,
    IconButton
} from '@mui/material/';

import CloseIcon from '@mui/icons-material/Close';

import { useSelector, useDispatch } from 'react-redux';
import {
    uploadVideoAction,
} from "../../../redux/uploadSlice"

export default function UploadVideo(props) {
    const {
        button_on,
        row_id
    } = props;

    const dispatch = useDispatch();
    const uploadStatus = useSelector((state) => state.upload.uploadStatus);

    const input_reader = useRef(null);
    const [videoId, setVideoId] = useState(null);

    const [display, setDisplay] = useState(0);

    const uploadBtnOnClick = () => {
        setVideoId(row_id);
        input_reader.current.click();
    };

    const inputFileChanged = (e) => {
        if (e.target.files) {
            let file = e.target.files[0];

            if (file !== undefined) {
                var video_full_name = file.name;
                var video_only_name = video_full_name.substr(0, video_full_name.lastIndexOf("."));

                //console.log('video_only_name: '+video_only_name+' === videoId: '+videoId)
                if (video_only_name === videoId) {
                    const formData = new FormData();
                    formData.append('file', file);

                    dispatch(uploadVideoAction(formData));

                    setDisplay(1)
                } else {
                    setDisplay(2)
                }
            }
        }
    };

    const closeMissMatchHandle = () => {
        setDisplay(0);
    };

    function renderSwitch(param) {
        switch (param) {
            case 1:
                return <Box>
                    <div>
                        {button_on && uploadStatus === 'uploading' ? 'uploading' : 'completed'}
                    </div>
                    <div>
                        {button_on && uploadStatus === 'uploading' ? <LinearProgress /> : null}
                    </div>
                </Box>;
            case 2:
                return <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{ color: 'red', padding: '1px 20px' }}>
                        no match
                    </Box>
                    <IconButton size='small' onClick={closeMissMatchHandle} sx={{ padding: '0px 0px' }}>
                        <CloseIcon fontSize='small' style={{ cursor: 'pointer', color: '#ff1744' }} />
                    </IconButton>
                </Box>;
            default:
                return <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {
                        button_on ?
                            <Avatar
                                variant="square"
                                onClick={uploadBtnOnClick}
                                sx={{
                                    bgcolor: '#2196f3',
                                    width: '100px',
                                    height: '24px',
                                    cursor: 'pointer',
                                    padding: '0px 0px',
                                    margin: '0px 0px'
                                }}
                            >
                                <div style={{ fontSize: '14px', paddingBottom: '2px' }}>
                                    upload video
                                </div>
                            </Avatar>
                            :
                            <div style={{ color: '#2196f3' }}>click to upload video</div>
                    }
                </Box>
        }
    }

    return (
        <div>
            {renderSwitch(display)}
            <input type="file" ref={input_reader} style={{ display: 'none' }} onChange={inputFileChanged} accept=".mp4, .ts" />
        </div>
    );
}