import React, { useState } from "react";
import {
    Box,
    Menu,
    MenuList,
    MenuItem,
    IconButton,
    ListItemIcon,
    Typography,
} from '@mui/material/';

import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HomeIcon from '@mui/icons-material/Home';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ConstructionIcon from '@mui/icons-material/Construction';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';

import green from '@mui/material/colors/green';
import deepPurple from '@mui/material/colors/deepPurple';
import lime from '@mui/material/colors/lime';
import indigo from '@mui/material/colors/indigo';
import pink from '@mui/material/colors/pink';
import purple from '@mui/material/colors/purple';


import { useDispatch } from 'react-redux';
import {
    resetCsvDataImportAction
} from "../redux/importSlice"

function AuthItemMenu(props) {
    const {
        display,
        update_main_display_action
    } = props;

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const openAccountHandleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const closeAccountHandleClick = (event) => {
        setAnchorEl(null);
    };

    return (
        <div >
            <IconButton
                color={'primary'}
                onClick={openAccountHandleClick}
            >
                <LaptopMacIcon />
            </IconButton>

            <Menu
                id="long-menu"
                disableScrollLock={true}
                anchorEl={anchorEl}
                keepMounted
                getcontentanchorel={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{ style: { width: 215 } }}
                open={open}
                onClose={closeAccountHandleClick}
            >
                <MenuList>
                    <MenuItem
                        disabled={display === 'home_page_landing'}
                        onClick={() => {
                            update_main_display_action('home_page_landing');
                            setAnchorEl(null);
                        }}
                        sx={{ padding: '8px 14px' }}
                    >
                        <ListItemIcon sx={{ '& svg': { color: pink[500] } }}>
                            <HomeIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Home Page</Typography>
                    </MenuItem>

                    <MenuItem
                        disabled={display === 'display_song_list'}
                        onClick={() => {
                            update_main_display_action('display_song_list');
                            dispatch(resetCsvDataImportAction());
                            setAnchorEl(null);
                        }}
                        sx={{ padding: '8px 14px' }}
                    >
                        <ListItemIcon sx={{ '& svg': { color: deepPurple[700] } }}>
                            <AccountBoxIcon />
                        </ListItemIcon>
                        <Box sx={{ fontSize: '16px' }}>
                            Song List
                        </Box>
                    </MenuItem>

                    <MenuItem
                        disabled={display === 'display_raw_list'}
                        onClick={() => {
                            update_main_display_action('display_raw_list');
                            setAnchorEl(null);
                        }}
                        sx={{ padding: '8px 14px' }}
                    >
                        <ListItemIcon sx={{ '& svg': { color: lime[800] } }}>
                            <ConstructionIcon />
                        </ListItemIcon>
                        <Box sx={{ fontSize: '16px' }}>
                            Raw List
                        </Box>
                    </MenuItem>

                    <MenuItem
                        disabled={display === 'display_download_list'}
                        onClick={() => {
                            update_main_display_action('display_download_list');
                            setAnchorEl(null);
                        }}
                        sx={{ padding: '8px 14px' }}
                    >
                        <ListItemIcon sx={{ '& svg': { color: indigo[500] } }}>
                            <CloudDownloadIcon />
                        </ListItemIcon>
                        <Box sx={{ fontSize: '16px' }}>
                            Download List
                        </Box>
                    </MenuItem>

                    <MenuItem
                        disabled={display === 'display_device_list'}
                        onClick={() => {
                            update_main_display_action('display_device_list');
                            setAnchorEl(null);
                        }}
                        sx={{ padding: '8px 14px' }}
                    >
                        <ListItemIcon sx={{ '& svg': { color: purple[500] } }}>
                            <DevicesOtherIcon />
                        </ListItemIcon>
                        <Box sx={{ fontSize: '16px' }}>
                            Device List
                        </Box>
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            localStorage.removeItem("member");
                            setAnchorEl(null);

                            if (display === 'home_page_landing') {
                                window.location.reload();
                            } else {
                                update_main_display_action('home_page_landing');
                            }
                            //update_main_display_action('home_page_landing');
                        }}
                        sx={{ padding: '8px 14px' }}
                    >
                        <ListItemIcon sx={{ '& svg': { color: green[700] } }}>
                            <LockOpenIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Sign Out</Typography>
                    </MenuItem>

                </MenuList>
            </Menu>
        </div>
    );
}

export default AuthItemMenu;