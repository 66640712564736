import * as React from 'react';
import {
    Box,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    Switch
} from '@mui/material/';

import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useDispatch } from 'react-redux';
import {
    addNewRawSongAction,
    updateCurrentRawSongAction,
    deleteRawSongAction
} from "../../../redux/uploadSlice"

import VideoFormDialog from './VideoFormDialog';
import DeleteConfirmDialog from '../DeleteConfirmDialog';

export default function EnhancedTableToolbar(props) {
    const {
        dataList,
        selected,
        setSelected,
        uploadType,
        setUploadType
    } = props;

    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [checked, setChecked] = React.useState(false);

    //console.log('selected: '+JSON.stringify(selected));
    //console.log('selected.length: '+JSON.stringify(selected.length))

    const addNewRowHandle = () => {
        setSelected([]);
        setOpenDialog(true);
    };

    const editRowHandle = () => {
        setOpenDialog(true);
    };

    const deleteRawSongDBHandle = () => {
        dispatch(deleteRawSongAction(selected));
        setSelected([]);
        setOpenConfirmDelete(false);
    };

    const getModalTitle = () => {
        let modalTitle = '';
        selected.length > 0 ? modalTitle = 'Update Selected Song' : modalTitle = 'Add New Song';
        return (modalTitle);
    }

    const submitSongForm = (payload) => {
        payload.id !== '' ? dispatch(updateCurrentRawSongAction(payload)) : dispatch(addNewRawSongAction(payload));
        setSelected([]);
        setOpenDialog(false);
    };

    const uploadTypeChangeHandle = (event) => {
        setChecked(event.target.checked);
        setSelected([]);

        if (event.target.checked === true) {
            setUploadType('multi');
        } else {
            setUploadType('single');
        }
    };

    return (
        <div>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(selected.length > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                <Box sx={{ display: 'flex', flex: '1 1 100%' }}>
                    <Switch
                        checked={checked}
                        onChange={uploadTypeChangeHandle}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {uploadType === 'multi' ? (
                        <Typography
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                            sx={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}
                        >
                            {selected.length} selected
                        </Typography>
                    ) : null}
                </Box>

                {selected.length > 0 ? (
                    <div style={{
                        display: 'flex',
                    }}>
                        <div style={{ marginRight: '12px' }}>
                            <Tooltip title="Delete" >
                                <IconButton onClick={() => setOpenConfirmDelete(true)}>
                                    <DeleteIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div style={{ marginRight: '10px', display: uploadType === 'multi' && selected.length > 1 ? 'none' : 'block' }} >
                            <Tooltip title="Edit" >
                                <IconButton onClick={editRowHandle} >
                                    <BuildCircleIcon color='primary' />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ) : null}

                <Tooltip title="Add">
                    <IconButton onClick={addNewRowHandle} >
                        <AddCircleIcon color='primary' />
                    </IconButton>
                </Tooltip>
            </Toolbar>
            {openDialog && <VideoFormDialog
                openDialog={openDialog}
                closeDialog={() => setOpenDialog(false)}
                title={getModalTitle()}
                selectedRow={selected.length > 0 ? dataList.filter(value => selected.includes(value.song_id)) : ''}
                submitSongForm={submitSongForm}
            />}
            {openConfirmDelete && <DeleteConfirmDialog
                openConfirmDelete={openConfirmDelete}
                closeConfirmDelete={() => setOpenConfirmDelete(false)}
                deleteRowHandle={() => deleteRawSongDBHandle()}
            />}
        </div>
    );
}