import * as React from 'react';
import {
    Box,
    Toolbar,
    Typography,
    Button
} from '@mui/material/';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { useDispatch } from 'react-redux';
import {
    startDownloadListAction,
    setIsDownloadingAction
} from "../../redux/downloadSlice"

export default function EnhancedTableToolbar(props) {
    const {
        selected,
        isDownloading
    } = props;

    const dispatch = useDispatch();

    const startDownloadHandle = () => {
        //console.log('downloadSelectedVideo - selected: ' + JSON.stringify(selected));
        dispatch(setIsDownloadingAction(true));
        dispatch(startDownloadListAction(selected[0]));
    };

    const stopDownloadHandle = () => {
        dispatch(setIsDownloadingAction(false));
    };

    return (
        <div>
            <Toolbar >
                <Box sx={{ display: 'flex', flex: '1 1 10%' }}>
                    <Typography
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                        //sx={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}
                    >
                        {selected.length} selected
                    </Typography>
                </Box>

                <div>
                    {
                        isDownloading ?
                            <Button
                                startIcon={<CloudUploadIcon />}
                                onClick={stopDownloadHandle}
                            >
                                Stop Download
                            </Button>
                            :
                            <Button
                                startIcon={<CloudUploadIcon />}
                                onClick={startDownloadHandle}
                            >
                                Start Download
                            </Button>
                    }
                </div>


            </Toolbar>
        </div>
    );
}