import React from 'react';
import {
    MenuItem,
    Select,
    Box,
    Paper,
    Avatar,
    Typography,
    Grid,
    InputBase
} from '@mui/material/';

import { useSelector, useDispatch } from 'react-redux';
import {
    fetchSongListAction,
    setSearchColumnAction,
    setSearchContentAction,
} from "../../redux/songSlice"

import { category } from '../../components/api/search';

function CategoryFilter() {
    const dispatch = useDispatch();

    const rowsTotalCount = useSelector((state) => state.song.rowsTotalCount);
    const searchColumn = useSelector((state) => state.song.searchColumn);
    const searchContent = useSelector((state) => state.song.searchContent);

    const categoryGroupOptions = () => {
        return category.map((data, i) => {
            return (
                <MenuItem
                    value={data.value}
                    key={i}
                    name={data.label}
                >
                    <Box sx={{ fontSize: '15px' }}>
                        {data.label}
                    </Box>
                </MenuItem>
            );
        });
    }

    const selectCategoryGroupChangeHandle = (event) => {
        event.preventDefault();

        dispatch(setSearchContentAction(''));
        dispatch(fetchSongListAction());
        dispatch(setSearchColumnAction(event.target.value));

    };

    const searchSongChanged = (event) => {
        event.preventDefault();
        dispatch(setSearchContentAction(event.target.value));
    }

    const searchSubmitHandle = (event) => {
        event.preventDefault();
        dispatch(fetchSongListAction());
    };

    return (
        <div>
            <Paper elevation={0} sx={{ background: '#e1f5fe', padding: '0px 10px', minHeight: '90px' }}>
                <Grid container={true} spacing={1} >
                    <Grid item xs={12} md={4} lg={4} xl={4} >
                        <Select
                            variant={'outlined'}
                            fullWidth
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        '& .MuiMenuItem-root': {
                                            padding: '20px 20px',
                                            minHeight: '34px',
                                            height: '36px',
                                        },
                                    }
                                }
                            }}
                            value={searchColumn}
                            defaultValue=""
                            onChange={selectCategoryGroupChangeHandle}
                            sx={{
                                height: 40,
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    //border: "1px solid black",
                                    borderWidth: '1px'
                                },
                            }}
                        >
                            {categoryGroupOptions()}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} xl={8}>
                        <Paper elevation={0} sx={{
                            background: '#e1f5fe',
                        }}>
                            <Paper>
                                <InputBase
                                    fullWidth
                                    placeholder={'please enter data to search'}
                                    value={searchContent}
                                    onChange={searchSongChanged}
                                    sx={{ pl: '10px', height: '40px' }}
                                />
                            </Paper>
                        </Paper>
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', pt: '8px' }}>
                    <Box sx={{ mt: '-2px', pl: '2px', display: 'flex', alignItems: 'center' }}>
                        <Typography fontSize='15px' >
                            Total {rowsTotalCount} songs
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto' }}>
                        <Avatar
                            variant="square"
                            onClick={searchSubmitHandle}
                            sx={{
                                bgcolor: '#01579b',
                                width: '100px',
                                height: '27px',
                                cursor: 'pointer'
                            }}
                        >
                            <Typography fontSize='12px' component="div">
                                Search
                            </Typography>
                        </Avatar>
                    </Box>
                </Box>
            </Paper>
        </div>

    );
}

export default CategoryFilter;