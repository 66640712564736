import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authHeader from "../components/token/auth-header";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT + "/api/";

const getApiParams = (page, rowsPerPage, searchColumn, searchContent, orderBy, order) => {
    let params = {};
    params["page"] = page;
    params["rowsPerPage"] = rowsPerPage;
    params["searchColumn"] = searchColumn;
    params["searchContent"] = searchContent;
    params["orderBy"] = orderBy;
    params["order"] = order;
    return params;
}


function removeFromArray(originalList, removeList) {
    const reverseRemoveList = removeList.sort(function (a, b) { return b - a; });
    return originalList.filter(value => !reverseRemoveList.includes(value.song_id));
}

const initialState = {
    message: '',
    isLoading: false,
    hasError: false,
    downloadList: [],
    rowsTotalCount: 0,
    page: 0,
    rowsPerPage: 10,
    searchColumn: 'downloadSongId',
    searchContent: '',
    orderBy: 'downloadSongId',
    order: 'asc',
    isDownloading: false
}

export const fetchDownloadListAction = createAsyncThunk(
    'download/fetchDownloadListAction',
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.download.page;
        const searchColumn = store.download.searchColumn;
        const searchContent = store.download.searchContent;
        const rowsPerPage = store.download.rowsPerPage;
        const orderBy = store.download.orderBy;
        const order = store.download.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        const response = await axios.get(BASE_URL + "fetch_download_list", { params, headers: authHeader() })

        return response.data
    }
)

export const startDownloadListAction = createAsyncThunk(
    'download/startDownloadListAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.download.page;
        const searchColumn = store.download.searchColumn;
        const searchContent = store.download.searchContent;
        const rowsPerPage = store.download.rowsPerPage;
        const orderBy = store.download.orderBy;
        const order = store.download.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        const response = await axios.post(BASE_URL + 'start_download_list', data, { params, headers: authHeader() })

        if (store.download.isDownloading === true) {
            if (response.data.dataList.length > 0 && response.data.status === 'download_success') {
                console.log('---------> DOWNLOAD OK');
                thunkAPI.dispatch(startDownloadListAction(response.data.dataList[0]));
            }
        }
        

        return response.data
    }
)

export const sortVideoColumnFieldAction = createAsyncThunk(
    'download/sortVideoColumnFieldAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.download.page;
        const rowsPerPage = store.download.rowsPerPage;
        const searchColumn = store.download.searchColumn;
        const searchContent = store.download.searchContent;

        const { orderBy, order } = data;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        const response = await axios.get(BASE_URL + "fetch_download_list", { params, headers: authHeader() });
        
        return response.data
    }
)

export const addNewRawSongAction = createAsyncThunk(
    'download/addNewRawSongAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.download.page;
        const searchColumn = store.download.searchColumn;
        const searchContent = store.download.searchContent;
        const rowsPerPage = store.download.rowsPerPage;
        const orderBy = store.download.orderBy;
        const order = store.download.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        const response = await axios.post(BASE_URL + 'add_new_raw_song', data, { params, headers: authHeader() })

        return response.data
    }
)

export const deleteRawSongAction = createAsyncThunk(
    'download/deleteRawSongAction',
    async (id, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.download.page;
        const searchColumn = store.download.searchColumn;
        const searchContent = store.download.searchContent;
        const rowsPerPage = store.download.rowsPerPage;
        const orderBy = store.download.orderBy;
        const order = store.download.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        const response = await axios.post(BASE_URL + 'delete_raw_song', id, { params, headers: authHeader() })

        return response.data
    }
)

export const updateCurrentRawSongAction = createAsyncThunk(
    'download/updateCurrentRawSongAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.download.page;
        const searchColumn = store.download.searchColumn;
        const searchContent = store.download.searchContent;
        const rowsPerPage = store.download.rowsPerPage;
        const orderBy = store.download.orderBy;
        const order = store.download.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);

        const response = await axios.put(BASE_URL + `update_current_raw_song/${data.id}`, data, { params, headers: authHeader() })

        return response.data
    }
)


export const downloadSlice = createSlice({
    name: 'download',
    initialState,
    reducers: {
        setIsDownloadingAction: (state, action) => {
            state.isDownloading = action.payload
        },
        deleteSelectedRowsAction: (state, action) => {
            state.csvList = removeFromArray(state.csvList, action.payload)
            state.csvListLength = state.csvList.length
        },
        setPageAction: (state, action) => {
            state.page = action.payload
        },
        setRowsPerPageAction: (state, action) => {
            state.rowsPerPage = action.payload
        },
        setSearchColumnAction: (state, action) => {
            state.searchColumn = action.payload
        },
        setSearchContentAction: (state, action) => {
            state.searchContent = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDownloadListAction.fulfilled, (state, action) => {
                //console.log(JSON.stringify(action.payload))
                state.downloadList = action.payload.dataList
                state.rowsTotalCount = action.payload.rowCount
                state.page = action.payload.page
                //state.rowsPerPage = action.payload.rowsPerPage
                state.searchContent = action.payload.searchContent
                state.orderBy = action.payload.orderBy
                state.order = action.payload.order
            })
            .addCase(startDownloadListAction.fulfilled, (state, action) => {
                state.downloadList = action.payload.dataList
                state.rowsTotalCount = action.payload.rowCount
                state.page = action.payload.page
                //state.rowsPerPage = action.payload.rowsPerPage
                state.searchContent = action.payload.searchContent
                state.orderBy = action.payload.orderBy
                state.order = action.payload.order
            })
    }
})

export const {
    setIsDownloadingAction,
    setPageAction,
    setRowsPerPageAction,
    deleteSelectedRowsAction,
    setSearchColumnAction,
    setSearchContentAction,
    resetRawVideoAction
} = downloadSlice.actions


export default downloadSlice.reducer;