import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material/';

function DeviceFormDialog(props) {
    const {
        openDialog,
        closeDialog,
        title,
        selected,
        submitDeviceApiRequest,
    } = props;

    //console.log('DeviceFormDialog - selected: '+JSON.stringify(selected))

    const [serial, setSerial] = useState(selected !== null ? selected.device_serial : '');
    //console.log('DeviceFormDialog - serial: '+serial);

    const deviceSerialChange = (event) => {
        setSerial(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const deviceObject = {};
        if (selected !== null) deviceObject['id'] = selected.id;
        deviceObject['device_serial'] = serial;

        submitDeviceApiRequest(deviceObject);
    };

    return (
        <div>
            <Dialog open={openDialog} onClose={closeDialog}>
                <DialogTitle id="form-dialog-title" color="primary" sx={{ paddingTop: '30px' }}>
                    {title}
                </DialogTitle>
                <DialogContent sx={{ minWidth: { xs: '260px', md: '400px' }}}>
                    <Box component="form" onSubmit={handleSubmit} >
                        <div spellCheck="false" style={{ marginBottom: '10px' }} >
                            <Typography sx={{ fontSize: '14px' }}>Serial Number Id:</Typography>
                            <TextField
                                id="songId"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={serial}
                                onChange={deviceSerialChange}
                                autoComplete='off'
                                //disabled={selected !== null ? false : true}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderWidth: '1px'
                                        },
                                    }
                                }}
                            />
                        </div>

                    </Box>
                </DialogContent>
                <DialogActions sx={{ pr: '14px', pb: '20px' }}>
                    <Button onClick={closeDialog} color="primary">Cancel</Button>
                    <Button form="companyForm" color="primary" type="submit" onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default DeviceFormDialog;