import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authHeader from "../components/token/auth-header";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT + "/api/";

const getApiParams = (page, rowsPerPage, searchColumn, searchContent, orderBy, order) => {
    let params = {};
    params["page"] = page;
    params["rowsPerPage"] = rowsPerPage;
    params["searchColumn"] = searchColumn;
    params["searchContent"] = searchContent;
    params["orderBy"] = orderBy;
    params["order"] = order;
    return params;
}

const updateVideoState = (state, payload) => {
    state.videoList = payload.dataList
    state.rowsTotalCount = payload.rowCount
    //state.page = payload.page
    //state.rowsPerPage = payload.rowsPerPage
    state.searchContent = payload.searchContent
    state.orderBy = payload.orderBy
    state.order = payload.order
}

function removeFromArray(originalList, removeList) {
    const reverseRemoveList = removeList.sort(function (a, b) { return b - a; });
    return originalList.filter(value => !reverseRemoveList.includes(value.song_id));
}

const initialState = {
    message: '',
    isLoading: false,
    hasError: false,
    videoList: [],
    uploadStatus: '',
    rowsTotalCount: 0,
    page: 0,
    rowsPerPage: 5,
    searchColumn: 'song_id',
    searchContent: '',
    orderBy: 'song_id',
    order: 'asc'
}

export const fetchVideoListAction = createAsyncThunk(
    'upload/fetchVideoListAction',
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.upload.page;
        const searchColumn = store.upload.searchColumn;
        const searchContent = store.upload.searchContent;
        const rowsPerPage = store.upload.rowsPerPage;
        const orderBy = store.upload.orderBy;
        const order = store.upload.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);

        const response = await axios.get(BASE_URL + "fetch_video_list", { params, headers: authHeader() })
        return response.data
    }
)

export const sortVideoColumnFieldAction = createAsyncThunk(
    'song/sortVideoColumnFieldAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.upload.page;
        const rowsPerPage = store.upload.rowsPerPage;
        const searchColumn = store.upload.searchColumn;
        const searchContent = store.upload.searchContent;

        const { orderBy, order } = data;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        const response = await axios.get(BASE_URL + "fetch_video_list", { params, headers: authHeader() });
        
        return response.data
    }
)

export const uploadVideoAction = createAsyncThunk(
    'upload/uploadVideoAction',
    async (formData, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.upload.page;
        const searchColumn = store.upload.searchColumn;
        const searchContent = store.upload.searchContent;
        const rowsPerPage = store.upload.rowsPerPage;
        const orderBy = store.upload.orderBy;
        const order = store.upload.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        const response = await axios.post(BASE_URL + "upload_raw_video", formData, { params, headers: authHeader() });

        return response.data
    }
)

export const addNewRawSongAction = createAsyncThunk(
    'upload/addNewRawSongAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.upload.page;
        const searchColumn = store.upload.searchColumn;
        const searchContent = store.upload.searchContent;
        const rowsPerPage = store.upload.rowsPerPage;
        const orderBy = store.upload.orderBy;
        const order = store.upload.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        const response = await axios.post(BASE_URL + 'add_new_raw_song', data, { params, headers: authHeader() })

        return response.data
    }
)

export const deleteRawSongAction = createAsyncThunk(
    'upload/deleteRawSongAction',
    async (id, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.upload.page;
        const searchColumn = store.upload.searchColumn;
        const searchContent = store.upload.searchContent;
        const rowsPerPage = store.upload.rowsPerPage;
        const orderBy = store.upload.orderBy;
        const order = store.upload.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);
        const response = await axios.post(BASE_URL + 'delete_raw_song', id, { params, headers: authHeader() })

        return response.data
    }
)

export const updateCurrentRawSongAction = createAsyncThunk(
    'upload/updateCurrentRawSongAction',
    async (data, thunkAPI) => {
        const store = thunkAPI.getState();
        const page = store.upload.page;
        const searchColumn = store.upload.searchColumn;
        const searchContent = store.upload.searchContent;
        const rowsPerPage = store.upload.rowsPerPage;
        const orderBy = store.upload.orderBy;
        const order = store.upload.order;

        const params = getApiParams(page, rowsPerPage, searchColumn, searchContent, orderBy, order);

        const response = await axios.put(BASE_URL + `update_current_raw_song/${data.id}`, data, { params, headers: authHeader() })

        return response.data
    }
)


export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        deleteSelectedRowsAction: (state, action) => {
            state.csvList = removeFromArray(state.csvList, action.payload)
            state.csvListLength = state.csvList.length
        },
        setPageAction: (state, action) => {
            state.page = action.payload
        },
        setRowsPerPageAction: (state, action) => {
            state.rowsPerPage = action.payload
        },
        setSearchColumnAction: (state, action) => {
            state.searchColumn = action.payload
        },
        setSearchContentAction: (state, action) => {
            state.searchContent = action.payload
        },
        resetRawVideoAction: (state) => {
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadVideoAction.fulfilled, (state, action) => {
                state.uploadStatus = 'completed'
                updateVideoState(state, action.payload)
            })
            .addCase(uploadVideoAction.pending, (state) => {
                state.uploadStatus = 'uploading'
            })
            .addCase(uploadVideoAction.rejected, (state) => {
                state.uploadStatus = 'error'
            })

            .addCase(fetchVideoListAction.fulfilled, (state, action) => {
                //console.log(JSON.stringify(action.payload))
                updateVideoState(state, action.payload)
            })

            .addCase(sortVideoColumnFieldAction.fulfilled, (state, action) => {
                updateVideoState(state, action.payload)
            })

            .addCase(addNewRawSongAction.fulfilled, (state, action) => {
                state.message = action.payload.message
                if (action.payload.dataList !== null) {
                    updateVideoState(state, action.payload)
                }
            })

            .addCase(updateCurrentRawSongAction.fulfilled, (state, action) => {
                state.message = action.payload.message
                if (action.payload.dataList !== null) {
                    updateVideoState(state, action.payload)
                }
            })

            .addCase(deleteRawSongAction.fulfilled, (state, action) => {
                state.message = action.payload.message
                updateVideoState(state, action.payload)
            })

    },
})

export const {
    setPageAction,
    setRowsPerPageAction,
    deleteSelectedRowsAction,
    setSearchColumnAction,
    setSearchContentAction,
    resetRawVideoAction
} = uploadSlice.actions


export default uploadSlice.reducer;