import * as React from 'react';
import {
    Toolbar,
    IconButton,
} from '@mui/material/';

import DeleteIcon from '@mui/icons-material/Delete';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useDispatch } from 'react-redux';
import {
    registerNewDeviceAction,
    updateCurrentDeviceAction,
    deleteCurrentDeviceAction
} from "../../redux/deviceSlice"

import DeviceFormDialog from './DeviceFormDialog';
import DeleteConfirmDialog from './DeleteConfirmDialog';

export default function DeviceTableToolbar(props) {
    const {
        //dataList,
        selected,
        setSelected,
    } = props;

    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    //const [checked, setChecked] = React.useState(false);

    //console.log('selected: ' + JSON.stringify(selected));

    const addNewRowHandle = () => {
        setSelected(null);
        setOpenDialog(true);
    };

    const editRowHandle = () => {
        setOpenDialog(true);
    };

    const deleteRawSongDBHandle = () => {
        dispatch(deleteCurrentDeviceAction(selected.id));
        setSelected(null);
        setOpenConfirmDelete(false);
    };

    const getModalTitle = () => {
        let modalTitle = '';
        selected !== null ? modalTitle = 'Update Selected Device' : modalTitle = 'Register New Device';
        return (modalTitle);
    }

    const submitDeviceApiRequest = (payload) => {
        //console.log('payload: ' + JSON.stringify(payload))

        payload.id !== undefined ? dispatch(updateCurrentDeviceAction(payload)) : dispatch(registerNewDeviceAction(payload));
        setSelected(null);
        setOpenDialog(false);
    };

    return (
        <div>
            <Toolbar
                variant='dense'
                sx={{
                    padding: '0px 0px',
                }}
            >
                {selected !== null ? (
                    <div style={{
                        display: 'flex',
                    }}>
                        <div style={{ marginRight: '12px' }}>
                            <IconButton onClick={() => setOpenConfirmDelete(true)}>
                                <DeleteIcon color='primary' />
                            </IconButton>
                        </div>
                        <div style={{ marginRight: '10px' }} >
                            <IconButton onClick={editRowHandle} >
                                <BuildCircleIcon color='primary' />
                            </IconButton>
                        </div>
                    </div>
                ) : null}

                <IconButton onClick={addNewRowHandle} >
                    <AddCircleIcon color='primary' />
                </IconButton>
            </Toolbar>
            {openDialog && <DeviceFormDialog
                openDialog={openDialog}
                closeDialog={() => setOpenDialog(false)}
                title={getModalTitle()}
                selected={selected !== null ? selected : null}
                submitDeviceApiRequest={submitDeviceApiRequest}
            />}
            {openConfirmDelete && <DeleteConfirmDialog
                openConfirmDelete={openConfirmDelete}
                closeConfirmDelete={() => setOpenConfirmDelete(false)}
                deleteRowHandle={() => deleteRawSongDBHandle()}
            />}
        </div>
    );
}