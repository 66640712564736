import React, { useEffect } from 'react';
import {
    Container,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    TablePagination
} from '@mui/material/';

import { visuallyHidden } from '@mui/utils';

import { useSelector, useDispatch } from 'react-redux';
import {
    fetchSongListAction,
    setPageAction,
    setRowsPerPageAction,
    sortColumnFieldAction
} from "../../redux/songSlice"

import SongSearch from './SongSearch';

const columnTitle = [
    {
        id: 'id',
        label: 'Id',
    },
    {
        id: 'song_id',
        label: 'Song Id',
    },
    {
        id: 'song_title',
        label: 'Song Title',
    },
    {
        id: 'singer_name_1',
        label: 'Singer Name 1',
    },
    {
        id: 'singer_name_2',
        label: 'Singer Name 2',
    },
];

export default function SongListTable() {
    const dispatch = useDispatch();

    const songList = useSelector((state) => state.song.songList);
    const rowsTotalCount = useSelector((state) => state.song.rowsTotalCount);
    const page = useSelector((state) => state.song.page);
    const rowsPerPage = useSelector((state) => state.song.rowsPerPage);
    const order = useSelector((state) => state.song.order);
    const orderBy = useSelector((state) => state.song.orderBy);

    const [selected, setSelected] = React.useState();

    useEffect(() => {
        dispatch(fetchSongListAction());
    }, [page, rowsPerPage, dispatch]);

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(sortColumnFieldAction({ orderBy: property, order: isAsc ? 'desc' : 'asc' }));
    };

    const handleChangePage = (event, newPage) => {
        console.log('newPage: '+newPage);
        dispatch(setPageAction(newPage));
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(setRowsPerPageAction(parseInt(event.target.value, 10)));
        dispatch(setPageAction(0));
    };

    const handleClick = (index, row) => {
        setSelected(index);
    };

    return (
        <Container maxWidth="xl">
            <Box >
                <h1>Display Karaoke Song List {rowsPerPage}</h1>
            </Box>
            <div>
                <SongSearch />
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <colgroup >
                        <col style={{ width: '8%' }} />
                        <col style={{ width: '12%' }} />
                        <col style={{ width: '40%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '20%' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow >
                            {columnTitle.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.center ? 'center' : 'left'}
                                    padding="normal"
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {songList.map((row, index) => (
                            <TableRow
                                key={row.song_id}
                                selected={index === selected}
                                onClick={() => handleClick(index, row)}
                                //className={classes.tableRow}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    '& .MuiTableCell-root': {
                                        //color: row.import_status === 'not qualified' ? 'red' : null
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="left">{row.song_id}</TableCell>
                                <TableCell align="left">{row.song_title}</TableCell>
                                <TableCell align="left">{row.singer_name_1}</TableCell>
                                <TableCell align="left">{row.singer_name_2}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[3, 10, 20, 50, 100]}
                component="div"
                count={rowsTotalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Container>
    );
}