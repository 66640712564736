import React from 'react';
import {
    Container,
} from '@mui/material/';

import { useSelector } from 'react-redux';

import ImportActivity from "./import/ImportActivity";
import VideoListTable from "./upload/VideoListTable";
import CsvListTable from "./import/CsvListTable";

function UploadSong() {
    const uploadType = useSelector((state) => state.import.uploadType);

    return (
        <Container maxWidth="xl">
            {
                uploadType === 'video_upload' ?
                    <h1>Upload Karaoke Video</h1>
                    :
                    <h1>Import Karaoke Song</h1>
            }
            <div>
                <ImportActivity />
            </div>

            {/* {console.log('::: Upload Type: ' + uploadType)} */}
            {uploadType === 'video_upload' ?
                <VideoListTable />
                :
                <CsvListTable />}
        </Container>
    );
}

export default UploadSong;